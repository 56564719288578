import { useAppContext } from "@context/app";
import { useRouter } from "next/router";

const useProgressBar = () => {
  const { currency } = useAppContext();
  const { locale } = useRouter();
  const isRTL = locale?.includes("ar-");

  return { currency, isRTL };
};

export default useProgressBar;
