import { IUserProfileInput } from "@types";
import { userApi } from "gate";

export const segmentIdentify = (userId: any, props: any) => {
  try {
    // (global as any)?.analytics?.identify(`${userId}`, props);
    (global as any)?.analytics?.identify(userId ? `${userId}` : undefined, props);
  } catch (e) {
    console.log(e);
  }
};

export const segmentReset = () => {
  try {
    (global as any)?.analytics?.reset();
  } catch (e) {
    console.log(e);
  }
};

export const segmentEvent = (event_name: any, props: any) => {
  const traits =
    (global as any)?.analytics?.user?.()?.traits?.() ||
    JSON.parse(localStorage.getItem("ajs_user_traits") ?? "{}");
  try {
    (global as any)?.analytics?.track(event_name, props, {
      traits: traits,
    });
  } catch (e) {
    console.log(e);
  }
};

export const segmentGetAnonymousId = () => {
  try {
    if ((global as any)?.analytics?.user?.()) {
      return (global as any)?.analytics.user().anonymousId();
    } else if (localStorage.getItem("ajs_anonymous_id")) {
      return localStorage.getItem("ajs_anonymous_id");
    } else {
      setTimeout(() => {
        return (global as any)?.analytics?.user().anonymousId();
      }, 2000);
    }
  } catch (e) {
    console.log(e);
  }
};

export const readyAnonymousId = () => {
  try {
    if ((global as any)?.analytics?.user?.()) {
      return (global as any)?.analytics.user().anonymousId();
    }
  } catch (e) {
    console.log(e);
  }
};

export const setSegmentIdentifyPhoneNumber = (user: IUserProfileInput, anonymousId: string) => {
  try {
    if ((global as any)?.analytics?.user?.()?.traits?.()) {
      userApi
        .getUserPhoneNumber()
        .fetch()
        .then((res) => {
          if (res.data?.phone) {
            segmentIdentify(user.id, {
              email: user.email,
              userId: user.id,
              name: user.name,
              $onesignal_user_id: user.id,
              anonymousId: anonymousId,
              phone: res.data.phone,
            });
          } else {
            basicIdentify(user, anonymousId);
          }
        })
        .catch((e) => {
          console.log(e);
          basicIdentify(user, anonymousId);
        });
    } else {
      basicIdentify(user, anonymousId);
    }
  } catch (e) {
    console.log(e);
  }
};

const basicIdentify = (user: IUserProfileInput, anonymousId: string) => {
  segmentIdentify(user.id, {
    email: user.email,
    userId: user.id,
    name: user.name,
    $onesignal_user_id: user.id,
    anonymousId: anonymousId,
  });
};
