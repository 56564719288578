import { useEffect } from "react";
import { BroadcastChannel } from "broadcast-channel";

const useBroadcastChannel = (channelName: string, handleMessage: (event: MessageEvent) => void) => {
  useEffect(() => {
    const channel = new BroadcastChannel(channelName);

    channel.addEventListener("message", handleMessage);

    return () => {
      channel.removeEventListener("message", handleMessage);
      channel.close();
    };
  }, [channelName, handleMessage]);
};

export default useBroadcastChannel;
