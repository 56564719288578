import type { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {}

const LogoutIcon: React.FC<Props> = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9994 4.77513V19.2239C11.9996 19.7635 11.7548 20.2741 11.3338 20.6118C10.9128 20.9495 10.3613 21.0778 9.83453 20.9606L5.38867 19.9722C4.57502 19.7915 3.99609 19.0699 3.99609 18.2365V5.76354C3.99609 4.92975 4.57559 4.208 5.38967 4.02782L9.83553 3.03941C10.362 2.92235 10.9132 3.0506 11.3339 3.38808C11.7547 3.72556 11.9994 4.23577 11.9994 4.77513Z"
        stroke={props.stroke || "#F60000"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.99826 13.0204V11.0196"
        stroke={props.stroke || "#F60000"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.0021 7.99834V5.95548C20.0022 5.42479 19.7915 4.91578 19.4162 4.54052C19.041 4.16526 18.5319 3.95451 18.0013 3.95465H15"
        stroke={props.stroke || "#F60000"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.0021 16.0016V18.0025C20.0022 18.5332 19.7915 19.0422 19.4162 19.4174C19.041 19.7927 18.5319 20.0035 18.0013 20.0033H15"
        stroke={props.stroke || "#F60000"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 12H20.0021"
        stroke={props.stroke || "#F60000"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.002 14.0008L20.0028 12L18.002 9.99915"
        stroke={props.stroke || "#F60000"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LogoutIcon;
