const arabicNumbers: Array<string> = ["۰", "۱", "۲", "۳", "٤", "٥", "٦", "۷", "۸", "۹"];

type StringInput = number | string | null;
export const separateMoneyNumbers = (str: string) => {
  if (str) {
    const fractions = str.split(".");
    let res = fractions[0]
      .split("")
      .reverse()
      .join("")
      .match(/.{1,3}/g)
      ?.join(",")
      .split("")
      .reverse()
      .join("");
    if (fractions.length > 1) res = res + "." + fractions[1];
    return res;
  }
  return str;
};

export function toArabicNumber(n?: StringInput) {
  if (typeof n === "undefined" || n === null) return "";

  // let chars: Array<string> = n.toString().split("");
  // let convert = true;
  // for (let i: number = 0; i < chars.length; i++) {
  //   if (/^[A-Za-z]$/g.test(chars[i])) {
  //     convert = false;
  //   } else if (!/\d/.test(chars[i]) && chars[i] !== " ") {
  //     convert = true;
  //   }
  //   if (/\d/.test(chars[i]) && convert) {
  //     chars[i] = arabicNumbers[Number(chars[i])];
  //   }
  // }
  // return chars.join("");
  return n;
}

export function convertNumber(locale: string = "en", n?: StringInput) {
  if (typeof n === "undefined" || n === null) return "";

  // if (locale?.includes("ar")) {
  //   // let chars: Array<string> = n.toString().split("");
  //   // let convert = true;
  //   // for (let i: number = 0; i < chars.length; i++) {
  //   //   if (/^[A-Za-z]$/g.test(chars[i])) {
  //   //     convert = false;
  //   //   } else if (!/\d/.test(chars[i]) && chars[i] !== " ") {
  //   //     convert = true;
  //   //   }
  //   //   if (/\d/.test(chars[i]) && convert) {
  //   //     chars[i] = arabicNumbers[Number(chars[i])];
  //   //   }
  //   // }
  //   // return chars.join("");
  //   return n;
  // } else {
  //   return n;
  // }
  return n;
}

export function convertStringNumber(locale: string = "en", n?: StringInput) {
  if (typeof n === "undefined" || n === null) return "";
  // if (locale?.includes("ar-")) {
  //   return n;
  //   // return n.toString().replace(/\d/g, (d) => "۰۱۲۳٤٥٦۷۸۹"[d as any]);
  // } else {
  //   return n;
  // }

  return n;
}
