import type { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> { }

const ReferralIcon: React.FC<Props> = (props) => {
  return (
    <svg {...props} width={props.width || "24"} height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group">
        <path id="Path" fillRule="evenodd" clipRule="evenodd" d="M13.8723 16.5756L6.29273 20.3654C5.52995 20.7467 4.60869 20.5972 4.00566 19.9942V19.9942C3.40263 19.3912 3.25313 18.4699 3.63452 17.7071L7.42431 10.1276C7.56805 9.84003 7.84127 9.63913 8.15858 9.58763C8.47589 9.53614 8.79861 9.64033 9.02591 9.86765L14.1322 14.974C14.3595 15.2013 14.4637 15.524 14.4122 15.8413C14.3607 16.1586 14.1598 16.4318 13.8723 16.5756Z" stroke={props.stroke || "#333333"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path id="Path_2" d="M5 3V3.5" stroke={props.stroke || "#333333"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path id="Path_3" d="M5 7V6.5" stroke={props.stroke || "#333333"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path id="Path_4" d="M7 5H6.5" stroke={props.stroke || "#333333"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path id="Path_5" d="M3 5H3.5" stroke={props.stroke || "#333333"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path id="Path_6" d="M12 3C12.5064 3.25322 12.8797 3.7117 13.025 4.25892C13.1704 4.80614 13.0737 5.38942 12.7596 5.86052L12 7" stroke={props.stroke || "#333333"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path id="Path_7" d="M17 11.9999L18.1395 11.2402C18.6106 10.9262 19.1939 10.8295 19.7411 10.9748C20.2883 11.1202 20.7468 11.4935 21 11.9999" stroke={props.stroke || "#333333"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path id="Path_8" d="M19 17V17.5" stroke={props.stroke || "#333333"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path id="Path_9" d="M19 21V20.5" stroke={props.stroke || "#333333"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path id="Path_10" d="M21 19H20.5" stroke={props.stroke || "#333333"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path id="Path_11" d="M17 19H17.5" stroke={props.stroke || "#333333"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path id="Path_12" d="M19 3V3.5" stroke={props.stroke || "#333333"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path id="Path_13" d="M19 7V6.5" stroke={props.stroke || "#333333"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path id="Path_14" d="M21 5H20.5" stroke={props.stroke || "#333333"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path id="Path_15" d="M17 5H17.5" stroke={props.stroke || "#333333"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path id="Path_16" d="M14 10L15 9" stroke={props.stroke || "#333333"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </svg>

  );
};

export default ReferralIcon;
