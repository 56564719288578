import type { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {}

const WalletIcon: React.FC<Props> = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.5043 6.95113L16.1384 3.79672C15.5013 2.94719 14.3115 2.74338 13.4279 3.33243L7.92969 6.99791"
        stroke={props.stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5049 11.4998H21.006C21.5585 11.4998 22.0064 11.9477 22.0064 12.5002V15.5015C22.0064 16.054 21.5585 16.5019 21.006 16.5019H18.5049C17.1237 16.5019 16.0039 15.3821 16.0039 14.0008V14.0008C16.0039 12.6195 17.1237 11.4998 18.5049 11.4998V11.4998Z"
        stroke={props.stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.0036 11.4998V9.49897C21.0036 8.11768 19.8838 6.99792 18.5026 6.99792H5.49714C4.11585 6.99792 2.99609 8.11768 2.99609 9.49897V18.5027C2.99609 19.884 4.11585 21.0038 5.49714 21.0038H18.5026C19.8838 21.0038 21.0036 19.884 21.0036 18.5027V16.5019"
        stroke={props.stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WalletIcon;
