import type { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {}

const GiftIcon: React.FC<Props> = (props) => {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 21H6C4.35 21 3 19.65 3 18V6C3 4.35 4.35 3 6 3H18C19.65 3 21 4.35 21 6V18C21 19.65 19.65 21 18 21Z"
        stroke={props.stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0003 11.258C12.0003 8.906 13.6333 7 15.6473 7C16.5143 7 17.2173 7.703 17.2173 8.57C17.2173 10.464 14.8803 12 11.9983 12C9.1163 12 6.7793 10.465 6.7793 8.57C6.7793 7.703 7.4823 7 8.3503 7C10.3643 7 11.9973 8.906 11.9973 11.258"
        stroke={props.stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 15.854C10.208 15.854 11.998 13.764 11.998 11.186L12.002 11.2C12.002 13.778 13.792 15.868 16 15.868"
        stroke={props.stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 3V21"
        stroke={props.stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 12H3"
        stroke={props.stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default GiftIcon;
