import {
  IGiftCardDetailInput,
  IGiftCardInput,
  IGiftCardPurchaseOutput,
  IUserGiftCardInput,
  IWalletChargeInput,
  IWalletChargeOutput,
  IWalletHistoryInput,
  IWalletInput,
} from "@types";
import gate from "../gate";
import client from "../http-clients/api";

const WalletApi = {
  getWallet: () =>
    gate.get<IWalletInput, never, never>({
      client,
      url: "wallet",
    }),
  chargeWallet: (data: IWalletChargeOutput) =>
    gate.post<IWalletChargeInput, IWalletChargeOutput, never>({
      client,
      url: "wallet/charge",
      data,
    }),
  history: (curser?: string) => {
    const url = curser ? `wallet/history/${curser}` : "wallet/history";
    return gate.get<IWalletHistoryInput, never, never>({
      client,
      url: url,
    });
  },

  redeemGiftCart: (data: { code: string }) =>
    gate.post<any, { code: string }, never>({
      client,
      url: "wallet/redeem",
      data,
    }),
  getGiftCardList: () =>
    gate.get<IGiftCardInput, never, never>({
      client,
      url: "giftcard",
    }),
  PurchaseGiftCard: (data: IGiftCardPurchaseOutput) =>
    gate.post<IWalletChargeInput, IGiftCardPurchaseOutput, never>({
      client,
      url: "giftcard/purchase",
      data,
    }),
  getMyGiftCardList: () =>
    gate.get<IUserGiftCardInput, never, never>({
      client,
      url: "giftcard/mine",
    }),
  getGiftCardDetail: (id: number) =>
    gate.get<IGiftCardDetailInput, never, never>({
      client,
      url: `giftcard/${id}`,
    }),
};

export default WalletApi;
