import type { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {}

const LoginIcon: React.FC<Props> = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.0028 17.0021V19.0029C20.0028 20.1084 19.1074 21.0037 18.0019 21.0037H5.99693C4.89147 21.0037 3.99609 20.1084 3.99609 19.0029V4.99708C3.99609 3.89162 4.89147 2.99625 5.99693 2.99625H18.0019C19.1074 2.99625 20.0028 3.89162 20.0028 4.99708V6.99791"
        stroke={props.stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.99826 10.9996V13.0004"
        stroke={props.stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.99609 18.9999C3.99609 20.4875 5.56175 21.4549 6.8913 20.7897L10.893 18.7888C11.5712 18.4487 11.9994 17.7564 11.9994 16.9981V2.99625"
        stroke={props.stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.004 12H16.002"
        stroke={props.stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.0028 9.99916L16.002 12L18.0028 14.0008"
        stroke={props.stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LoginIcon;
