import type { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {}

const PathIcon: React.FC<Props> = (props) => {
  return (
    <svg
      width="6"
      height="10"
      viewBox="0 0 6 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 9L5 5L1 1"
        stroke={props.stroke ? props.stroke : "#008AF6"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PathIcon;
