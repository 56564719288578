import Lottie from "lottie-react";
import animationData from "./lf20_o6nierbk.json";
interface LoadingProps {
  isCenter?: boolean;
}

const Loading: React.FC<LoadingProps> = ({ isCenter }) => {
  return isCenter ? (
    <div className=" flex justify-center items-center w-full h-full fixed inset-0" style={{ zIndex: 70 }}>
      <Lottie
        animationData={animationData}
        loop
        autoPlay
        className=" w-40 h-40 "
      />
    </div>
  ) : (
    <div className=" flex justify-center items-center w-screen h-screen fixed inset-0 " style={{ zIndex: 70 }}>
      <Lottie
        animationData={animationData}
        loop
        autoPlay
        className=" w-40 h-40"
      />
    </div>
  );
};

export default Loading;
