import {
  IHomeItemDetailInput,
  IProductDetailModel,
  ISmallProductModel,
} from "@types";
import { convertStringNumber } from "@utils/digits";
import Link from "next/link";
import { useRouter } from "next/router";
import ImagePrepare from "../imagePrepare";
import useParentSize from "@hooks/useParentSize";
import { generateProductLink } from "./linkGenerator";
import ProductPrice from "./productPrice";

interface CardProductProps {
  product: IProductDetailModel | ISmallProductModel | IHomeItemDetailInput;
  searchAction?: () => void;
  width?: number;
  source?: string;
  sourceEntity?: string;
  sourceSlug?: string;
  section?: string;
  sectionEntity?: string;
  sectionSlug?: string;
  q?: string;
}

const CardProduct: React.FC<CardProductProps> = ({
  product,
  searchAction,
  width,
  source,
  sourceEntity,
  sourceSlug,
  section,
  sectionEntity,
  sectionSlug,
  q,
}) => {
  const { locale } = useRouter();
  const [parentSize, parentRef] = useParentSize();
  const sku = product.skus?.find((sku) => sku.id === product.default_sku_id);
  if (!sku) return null
  const widthClass = width ? "w-[194px]" : `p-2 max-w-[194px] min-w-[180px] md:w-[194px] sm:w-[180px] xs:w-1/2 w-1/2`;
  const linkHref = generateProductLink(product, source, sourceSlug, sourceEntity, section, sectionEntity, sectionSlug, q);
  const badgeBackgroundColor = sku.price.badge?.background_color || "#FFD600";
  const badgeTextColor = sku.price.badge?.text_color;
  const badgeText = convertStringNumber(locale, sku.price.badge?.text)



  return (
    <div className={widthClass}>
      <Link href={linkHref} passHref prefetch={false}>
        <a onClick={searchAction}>
          <div className={`relative p-3 border rounded-xl text-[#333] ${width ? "h-[296px]" : "sm:h-[296px] xs:h-[280px] h-[280px]"}  flex flex-col`}>
            {(sku.buy_limit && sku.price.old_price) ? (
              <div className="z-10 absolute top-3 right-3 w-7 h-7 rounded-lg flex justify-center items-center" style={{ backgroundColor: badgeBackgroundColor, color: badgeTextColor }}>
                <p className="font-bold text-[10px]">{badgeText}</p>
              </div>) : null}
            <div className="w-full max-w-[180px] p-0 m-auto">
              <div className="w-full pt-[100%] relative overflow-hidden">
                <div className="w-full h-full absolute top-0 left-0 rounded-lg overflow-hidden" ref={parentRef}>
                  {sku.medias && <ImagePrepare alt={product.name} media={sku.medias[0]} width={parentSize.width} />}
                </div>
              </div>
            </div>
            <div className="w-full mt-5 bg-white flex flex-col justify-between flex-1">
              <h2 className="font-normal text-sm line-clamp-2 whitespace-normal">{convertStringNumber(locale, product.name)}</h2>
              <ProductPrice
                oldPrice={sku.price.old_price}
                price={sku.price.price}
                buyLimit={sku.buy_limit}
              />
            </div>
          </div>
        </a>
      </Link>
    </div>
  );
};

export default CardProduct;

