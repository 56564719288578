import type { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {}

const OrderIcon: React.FC<Props> = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 21H5C3.895 21 3 20.105 3 19V5C3 3.895 3.895 3 5 3H19C20.105 3 21 3.895 21 5V19C21 20.105 20.105 21 19 21Z"
        stroke={props.stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 8H11"
        stroke={props.stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 12H17"
        stroke={props.stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 16H17"
        stroke={props.stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.99801 7.75C6.86001 7.75 6.74801 7.862 6.75001 8C6.75001 8.138 6.86201 8.25 7.00001 8.25C7.13801 8.25 7.25001 8.138 7.25001 8C7.25001 7.862 7.13801 7.75 6.99801 7.75"
        stroke={props.stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.99801 11.75C6.86001 11.75 6.74801 11.862 6.75001 12C6.75001 12.138 6.86201 12.25 7.00001 12.25C7.13801 12.25 7.25001 12.138 7.25001 12C7.25001 11.862 7.13801 11.75 6.99801 11.75"
        stroke={props.stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.99801 15.75C6.86001 15.75 6.74801 15.862 6.75001 16C6.75001 16.138 6.86201 16.25 7.00001 16.25C7.13801 16.25 7.25001 16.138 7.25001 16C7.25001 15.862 7.13801 15.75 6.99801 15.75"
        stroke={props.stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default OrderIcon;
