import type { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {}

const SettingsIcon: React.FC<Props> = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.799 2.99625C13.2211 2.99625 13.5978 3.26128 13.7404 3.65852L14.2486 5.07411C14.3293 5.29672 14.4859 5.48375 14.6908 5.60233L16.1985 6.47269C16.4032 6.59082 16.6431 6.63264 16.8757 6.59074L18.3564 6.32363C18.7721 6.24803 19.1906 6.44183 19.4018 6.80783L20.2021 8.18841C20.4139 8.55342 20.3739 9.01208 20.1021 9.33489L19.1307 10.4834C18.9777 10.664 18.8937 10.8929 18.8936 11.1296V12.8704C18.8937 13.107 18.9777 13.336 19.1307 13.5166L20.1021 14.6651C20.3739 14.9879 20.4139 15.4466 20.2021 15.8116L19.4018 17.1922C19.1907 17.5578 18.7728 17.7515 18.3574 17.6764L16.8767 17.4092C16.6441 17.3674 16.4042 17.4092 16.1995 17.5273L14.6918 18.3977C14.4869 18.5162 14.3303 18.7033 14.2496 18.9259L13.7414 20.3415C13.5986 20.7391 13.2215 21.0041 12.799 21.0037H11.1984C10.7763 21.0037 10.3996 20.7387 10.257 20.3415L9.74877 18.9259C9.66812 18.7036 9.51198 18.5166 9.30758 18.3977L7.79896 17.5273C7.59423 17.4092 7.35429 17.3674 7.12167 17.4092L5.64106 17.6764C5.22529 17.752 4.80686 17.5582 4.59562 17.1922L3.79529 15.8116C3.58349 15.4466 3.62351 14.9879 3.89533 14.6651L4.86673 13.5166C5.01973 13.336 5.10374 13.107 5.10383 12.8704V11.1296C5.10374 10.8929 5.01973 10.664 4.86673 10.4834L3.90533 9.33489C3.63351 9.01208 3.59349 8.55342 3.80529 8.18841L4.60563 6.80783C4.81673 6.44222 5.23462 6.24849 5.65006 6.32363L7.13068 6.59074C7.3633 6.63264 7.60323 6.59082 7.80796 6.47269L9.31659 5.60233C9.52099 5.48338 9.67713 5.29643 9.75777 5.07411L10.266 3.65852C10.4075 3.26436 10.7796 3.00008 11.1984 2.99625H12.799Z"
        stroke={props.stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="11.9992"
        cy="12"
        r="2.75115"
        stroke={props.stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SettingsIcon;
