import { Popover, Transition } from "@headlessui/react";
import { Fragment } from "react";
import cn from "classnames";
import { useTranslations } from "next-intl";
import { useRouter } from "next/router";
import BurgerIcon from "@svg/BurgerIcon";
import { useAppContext } from "@context/app";
import { useUI } from "@context/ui-managed-context";
import LoginIcon from "@svg/LoginIcon";
import SettingsIcon from "@svg/SettingsIcon";
import SupportIcon from "@svg/SupportIcon";
import WalletIcon from "@svg/WalletIcon";
import GiftIcon from "@svg/GiftIcon";
import OrderIcon from "@svg/OrderIcon";
import AddressesIcon from "@svg/AddressesIcon";
import LogoutIcon from "@svg/LogoutIcon";
import auth from "@api/auth";
import {
  COOKIE_ACCESS_TOKEN_KEY,
  COOKIE_IGNORE_PHONE_VERIFICATION,
  COOKIE_IGNORE_PHONE_VERIFICATION_CHECKOUT,
  COOKIE_LOGIN_TOKEN_KEY,
} from "@utils/constants";
import { getCookie } from "@utils/Cookies";
import Close from "@svg/close";
import { convertStringNumber } from "@utils/digits";
import { segmentEvent, segmentGetAnonymousId, segmentIdentify, segmentReset } from "@utils/segment";
import { setExternalUserId } from "@utils/oneSignal";
import ReferralIcon from "@svg/referralIcon";
import OfferIcon from "@svg/offerIcon";
import useGTM from "@hooks/useGTM";

interface Props {
  className?: string;
}

const MobileMenu: React.FC<Props> = ({ className }) => {
  const t = useTranslations("");
  const {
    currency,
    walletBalance,
    setUserLoggedIn,
    setUserCart,
    user,
    country,
    area,
    syncData
  } = useAppContext();
  const { setModalView, openModal } = useUI();
  const { pushToDataLayer } = useGTM();
  const Cookies = getCookie();
  const { locale } = useRouter();
  const router = useRouter();
  const handelLogout = async () => {
    try {
      const res = await auth.logout().fetch();
      segmentReset()
      const anonymousId = await segmentGetAnonymousId()
      setExternalUserId(anonymousId)
      segmentIdentify(undefined, { $onesignal_user_id: anonymousId, anonymousId: anonymousId })
      segmentEvent("User Logged Out", {
        user_id: user.data?.id || -1,
        language: locale?.includes("en") ? "English" : "Arabic",
        country_id: country?.id,
        country_slug: country?.slug,
        area_id: area?.id,
        area_slug: area?.slug,
        from_page: "menu",
        from_entity: "",
        from_entity_slug: "",
        from_page_section: "",
        from_page_section_entity: "",
        from_page_section_slug: "",
      });
      pushToDataLayer("User Logged Out", {
        user_id: user.data?.id || -1,
        language: locale?.includes("en") ? "English" : "Arabic",
        country_id: country?.id,
        country_slug: country?.slug,
        area_id: area?.id,
        area_slug: area?.slug,
        from_page: "menu",
        from_entity: "",
        from_entity_slug: "",
        from_page_section: "",
        from_page_section_entity: "",
        from_page_section_slug: "",
      });
      setUserLoggedIn({ isLoggedIn: false });
      setUserCart([]);
      Cookies.set(
        COOKIE_ACCESS_TOKEN_KEY,
        res.data.access_token,
        {},
        365 * 2
      );
      Cookies.remove(COOKIE_LOGIN_TOKEN_KEY);
      Cookies.remove(COOKIE_IGNORE_PHONE_VERIFICATION)
      Cookies.remove(COOKIE_IGNORE_PHONE_VERIFICATION_CHECKOUT)
      router.push("/", undefined, { shallow: true }).finally(() => {
        router.reload();
      });
    } catch (error) { }
  };

  return (
    <Popover
      className={cn("relative h-6 flex justify-center items-center", className)}
      as="nav"
    >
      {({ close }) => (
        <>
          <Transition
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Popover.Overlay className="fixed inset-0 backdrop-brightness-50 z-[60]" />
          </Transition>
          <Popover.Button className="text-gray-500 z-[20] relative focus:outline-none " aria-label="Menu">
            <BurgerIcon />
            {
              user.isLoggedIn && syncData?.count_unseen_notification ? <p className={cn("bg-[#F60000] w-2 h-2 rounded-full absolute ", locale?.includes("ar-") ? "top-0 right-0" : "top-0 left-0")}></p>
                : null}
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom={`opacity-0  ${router.locale?.includes("en") ? "-translate-x-full" : "translate-x-full"
              }`}
            enterTo={`opacity-100  ${router.locale?.includes("en") ? "-translate-x-0" : "translate-x-0"
              }`}
            leave="transition ease-in duration-150"
            leaveFrom={`opacity-100  ${router.locale?.includes("en") ? "-translate-x-0" : "translate-x-0"
              }`}
            leaveTo={`opacity-0  ${router.locale?.includes("en") ? "-translate-x-full" : "translate-x-full"
              }`}
          >
            <Popover.Panel
              className={`px-8 pt-10 fixed top-[0px] z-[60] height-screen ${router.locale?.includes("en")
                ? "left-0 rounded-r-2xl"
                : "right-0 rounded-l-2xl"
                } w-screen overflow-hidden shadow-lg  max-w-[240px] transform bg-menu`}
            >
              <div className="mb-7">
                <div
                  className="w-10 h-10 rounded-full bg-[#2a96eb] flex items-center justify-center"
                  onClick={() => close()}
                >
                  <Close className=" text-white " width={10} height={10} />
                </div>
              </div>
              <div className=" py-1 mb-5">
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <img src="/svgs/logo.svg" alt="dabdoob" width="128" />
              </div>
              {!user.isLoggedIn && (
                <div className=" py-1 ">
                  <div>
                    <button
                      onClick={() => {
                        setModalView("LOGIN_VIEW");
                        openModal();
                        close();
                      }}
                      className={`${"text-white font-normal"} group flex rounded-md items-center w-full px-2 py-2 text-sm my-2`}
                    >
                      <LoginIcon
                        stroke={"#fff"}
                        className="w-5 h-5 mx-1"
                        aria-hidden="true"
                      />
                      {t("client_menu_loginsignup")}
                    </button>
                  </div>
                  <div>
                    <button
                      onClick={() =>
                        router.push("/settings").then(() => {
                          close();
                        })
                      }
                      className={`${"text-white font-normal"} group flex rounded-md items-center w-full px-2 py-2 text-sm my-2`}
                    >
                      <SettingsIcon
                        stroke={"#fff"}
                        className="w-5 h-5 mx-1"
                        aria-hidden="true"
                      />
                      {t("client_menu_settings")}
                    </button>
                  </div>
                  <div>
                    <button
                      onClick={() =>
                        router.push("/support").then(() => {
                          close();
                        })
                      }
                      className={`${"text-white font-normal"} group flex rounded-md items-center w-full px-2 py-2 text-sm my-2`}
                    >
                      <SupportIcon
                        stroke={"#fff"}
                        className="w-5 h-5 mx-1"
                        aria-hidden="true"
                      />
                      {t("w-supportMenu")}
                    </button>
                  </div>
                </div>
              )}
              {user.isLoggedIn && (
                <div className=" py-1 ">
                  <div>
                    <button
                      onClick={() =>
                        router.push("/wallet").then(() => {
                          close();
                        })
                      }
                      className={`${"text-white font-normal"} group flex rounded-md items-center  w-full px-2 py-2 text-sm my-2 `}
                    >
                      <WalletIcon
                        stroke={"#fff"}
                        className="w-5 h-5 mx-1"
                        aria-hidden="true"
                      />
                      <div className=" flex flex-1 overflow-hidden ">
                        <p>{t("client_wallet_wallet")}</p>

                        <p className=" flex space-x-1 left-to-right px-1">
                          (
                          <span style={{ direction: "rtl" }}>{currency} </span>
                          <span className=" w-max max-w-[56px] whitespace-nowrap overflow-hidden text-ellipsis">{convertStringNumber(router.locale, walletBalance)}</span>
                          )
                        </p>
                      </div>

                    </button>
                  </div>
                  <div>
                    <button
                      onClick={() =>
                        router.push("/offers").then(() => {
                          close();
                        })
                      }
                      className={`${"text-white font-normal"} group flex rounded-md items-center w-full px-2 py-2 text-sm my-2`}
                    >
                      <OfferIcon
                        stroke={"#fff"}
                        className="w-5 h-5 mx-1"
                        aria-hidden="true"
                      />
                      <p className=" flex-1 flex items-center justify-between">
                        <span>{t("client_menu_offers")}</span>
                        {syncData?.count_unseen_notification ? <span className=" w-6 h-6 rounded-full border border-[#eee] font-bold  flex justify-center items-center">{convertStringNumber(locale, syncData.count_unseen_notification)}</span>
                          : null}
                      </p>
                    </button>
                  </div>
                  <div>
                    <button
                      onClick={() =>
                        router.push("/giftCard").then(() => {
                          close();
                        })
                      }
                      className={`${"text-white font-normal"} group flex rounded-md items-center w-full px-2 py-2 text-sm my-2 `}
                    >
                      <GiftIcon
                        stroke={"#fff"}
                        className="w-5 h-5 mx-1"
                        aria-hidden="true"
                      />
                      {`${t("client_menu_giftcards")}`}
                    </button>
                  </div>

                  {syncData?.app_settings.referral_by_coupon ? <div>
                    <button
                      onClick={() =>
                        router.push("/referral").then(() => {
                          close();
                        })
                      }
                      className={`${"text-white font-normal"} group flex rounded-md items-center w-full px-2 py-2 text-sm my-2`}
                    >
                      <ReferralIcon
                        stroke={"#fff"}
                        className="w-5 h-5 mx-1"
                        aria-hidden="true"
                      />
                      {t("client_menu_myreferrals")}
                    </button>
                  </div> : null}
                  <div>
                    <button
                      onClick={() =>
                        router.push("/order").then(() => {
                          close();
                        })
                      }
                      className={`${"text-white font-normal"} group flex rounded-md items-center w-full px-2 py-2 text-sm my-2`}
                    >
                      <OrderIcon
                        stroke={"#fff"}
                        className="w-5 h-5 mx-1"
                        aria-hidden="true"
                      />
                      {t("clientmenu_myorders")}
                    </button>
                  </div>
                  <div>
                    <button
                      onClick={() =>
                        router.push("/addresses").then(() => {
                          close();
                        })
                      }
                      className={`${"text-white font-normal"} group flex rounded-md items-center w-full px-2 py-2 text-sm my-2`}
                    >
                      <AddressesIcon
                        stroke={"#fff"}
                        className="w-5 h-5 mx-1"
                        aria-hidden="true"
                      />
                      {t("client_menu_myaddresses")}
                    </button>
                  </div>
                  <div>
                    <button
                      onClick={() =>
                        router.push("/settings").then(() => {
                          close();
                        })
                      }
                      className={`${"text-white font-normal"} group flex rounded-md items-center w-full px-2 py-2 text-sm my-2`}
                    >
                      <SettingsIcon
                        stroke={"#fff"}
                        className="w-5 h-5 mx-1"
                        aria-hidden="true"
                      />
                      {t("client_menu_settings")}
                    </button>
                  </div>
                  <div>
                    <button
                      onClick={() =>
                        router.push("/support").then(() => {
                          close();
                        })
                      }
                      className={`${"text-white font-normal"} group flex rounded-md items-center w-full px-2 py-2 text-sm my-2`}
                    >
                      <SupportIcon
                        stroke={"#fff"}
                        className="w-5 h-5 mx-1"
                        aria-hidden="true"
                      />
                      {t("client_menu_support")}
                    </button>
                  </div>
                  <div>
                    <button
                      onClick={() => {
                        handelLogout();
                        close();
                      }}
                      className={`${"text-white font-normal"} group flex rounded-md items-center w-full px-2 py-2 text-sm my-2`}
                    >
                      <LogoutIcon
                        className="w-5 h-5 mx-1"
                        stroke="#fff"
                        aria-hidden="true"
                      />
                      {t("client_menu_logout")}
                    </button>
                  </div>
                </div>
              )}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default MobileMenu;
