import type { IUserLoginInput, IUserLoginOutput } from "@types";
import { COOKIE_UID_KEY } from "@utils/constants";
import { getCookie } from "@utils/Cookies";
import { AppGuid } from "@utils/guid";
import {
  IDeviceAuthInput,
  IDeviceAuthOutput,
  IUserForgotOutput,
  IUserGuestOutput,
  IUserSignUpInput,
  IUserSignUpOutput,
} from "types/api/auth";
import gate from "../gate";
import client from "../http-clients/api";
const Cookies = getCookie();
const x = {
  device: {
    name: "Web",
    capacity: "1GB",
    uid: Cookies.get(COOKIE_UID_KEY) || AppGuid.generate(),
    platform: "Web",
  },
  os: {
    version: "1.0.0",
    type: "Web",
  },
  bundle: "com.nizek.dabdoob-driver",
};

const auth = {
  deviceAuth: () =>
    gate.post<IDeviceAuthInput, IDeviceAuthOutput>({
      client,
      url: "device/auth",
      data: x,
    }),

  login: (data: IUserLoginOutput) =>
    gate.post<IUserLoginInput, IUserLoginOutput>({
      client,
      url: "user/login",
      data,
    }),
  signUp: (data: IUserSignUpOutput) =>
    gate.post<IUserSignUpInput, IUserSignUpOutput>({
      client,
      url: "user/register",
      data,
    }),
  guestLogin: (data: IUserGuestOutput) =>
    gate.post<IUserSignUpInput, IUserGuestOutput>({
      client,
      url: "user/login/guest",
      data,
    }),
  forgotPass: (data: IUserForgotOutput) =>
    gate.post<{ success: boolean }, IUserForgotOutput>({
      client,
      url: "user/forgot-password",
      data,
    }),
  refresh: (params: any) =>
    gate.post<any, never, any>({
      client,
      url: "TokenAuth/RefreshToken",
      data: undefined,
      params: params,
    }),
  logout: () =>
    gate.get<{ access_token: string }, never>({
      client,
      url: "user/logout",
    }),

  googleLogin: (data: { token: string }) =>
    gate.post<IUserLoginInput, { token: string }>({
      client,
      url: "user/login/google",
      data,
    }),
  facebookLogin: (data: { token: string }) =>
    gate.post<IUserLoginInput, { token: string }>({
      client,
      url: "user/login/facebook",
      data,
    }),
  appleLogin: (data: { token: string; code: string; name: "" }) =>
    gate.post<IUserLoginInput, { token: string; code: string; name: "" }>({
      client,
      url: "user/login/apple",
      data,
    }),
};

export default auth;
