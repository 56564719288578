import { useTranslations } from "next-intl";
import Button from "../Button/Button";
import PriceDisplay from "../PriceDisplay/PriceDisplay";

interface FooterProps {
  totalPrice: number;
  subtotalPrice: number;
  totalDiscount: number;
  cartLength: number;
  goToCartView: (close: () => void) => void;
  close: () => void;
}

const Footer: React.FC<FooterProps> = ({ totalPrice, cartLength, totalDiscount, subtotalPrice, goToCartView, close }) => {
  const t = useTranslations("");

  return (
    <div className="w-full h-max bg-white">
      {cartLength ? (
        <div className="w-full flex flex-col gap-3">
          {totalDiscount ? <div className="w-full border-b-2 border-dashed border-mui-divider pb-2 flex flex-col gap-2 "
            style={{
              borderImageSlice: 1,
              borderImageSource:
                'repeating-linear-gradient(90deg, #0000001F, #0000001F 8px, transparent 8px, transparent 15px)',
            }}
          >
            <div className="flex justify-between w-full border-t border-mui-divider pt-2 ">
              <p className=" text-mui-text-primary text-base font-medium capitalize">{t("client_checkoutprocess_subtotal")}</p>
              <PriceDisplay price={subtotalPrice} className="text-mui-text-primary !text-base !font-medium" />
            </div>
            <div className="flex justify-between w-full">
              <p className="text-mui-secondary-main text-base font-medium capitalize">{t("Discount")}</p>
              <PriceDisplay price={totalDiscount} className="text-mui-secondary-main !text-base !font-medium" isNegative />
            </div>
          </div> : null}
          <div className="flex justify-between w-full items-center" >
            <p className=" text-mui-text-primary text-xl font-semibold capitalize">{t("client_orderresults_total")}</p>
            <PriceDisplay price={totalPrice} className="text-mui-text-primary !text-xl" />
          </div>
          <div className="w-full flex flex-col gap-2">
            <Button size="small" label={t("client_buttons_continue")} theme="primary" onClick={() => goToCartView(close)} className=" !font-bold" />
            <Button size="small" label={t("client_keep_shopping")} theme="primaryOutline" onClick={() => close()} className=" !font-semibold" />
          </div>
        </div>
      ) : (
        <div className="w-full ">
          <div className="w-full">
            <Button size="small" label={t("client_buttons_startshopping")} theme="primaryOutline" onClick={() => close()} className=" !font-semibold" />
          </div>
        </div>
      )}
    </div>
  )
};


export default Footer