import type {
  ElementType,
  Ref,
} from "react";
import type { AsProps, ButtonProps } from "@types";
import React, { useRef, Fragment, createElement } from "react";
import cn from "classnames";
import Loader from "./loader";
import { useSyncRefs } from "@hooks/useSyncRef";
import { forwardRefWithAs, omit } from "@utils/ui";
import { useResolveButtonType } from "@hooks/useResolveButtonType";


let DEFAULT_BUTTON_TAG = "button" as const;

// eslint-disable-next-line react/display-name
const Button = forwardRefWithAs(function Button<
  TTag extends ElementType = typeof DEFAULT_BUTTON_TAG
>(
  {
    loading = false,
    className,
    theme,
    isDisabled,
    size = "normal",
    noAnimation,
    ...buttonProps
  }: AsProps<TTag> & ButtonProps,
  ref: Ref<HTMLElement>
) {
  const buttonRef = useRef<HTMLElement | null>(null);
  let _ref = useSyncRefs(buttonRef, ref);

  let passthroughProps = buttonProps;

  let propsWeControl = {
    ref: _ref,
    type: useResolveButtonType(buttonProps, buttonRef),
    disabled: loading || isDisabled,
    tabIndex: 0,
    className: cn(
      "relative disabled:cursor-not-allowed truncate focus:outline-none no-underline z-0 py-4 overflow-hidden text-center items-center justify-center flex",
      "transition-transform duration-200 ease-in-out border scale-1",
      !noAnimation && "hover:scale-[1.05]",
      (() => {
        switch (size) {
          case "slim":
            return "h-12 px-6 text-sm min-w-[4.6875rem] font-extrabold";
          case "normal":
            return "h-12 px-8 text-base min-w-[8.6875rem]";
          default:
            return "h-7 px-6 text-sm min-w-[6.1875rem]";
        }
      })(),
      (() => {
        switch (theme) {
          case "grayLight":
            return "bg-[#F5F5F5] text-black hover:text-black border-[#F5F5F5]";
          case "gray":
            return "bg-[#222222] text-white hover:text-white border-[#222222]";
          case "light":
            return "bg-white text-black hover:text-black border-white";
          case "lightBlue":
            return "bg-white text-[#008AF6] border";
          case "blueOutline":
            return "bg-white text-[#008AF6] border border-[#008AF6]";
          case "dark":
            return "bg-black text-white hover:text-white border-black";
          case "primary":
            return "bg-primary text-white hover:text-white border-primary";
          case "secondary":
            return "bg-transparent text-primary border-primary";
          default:
            return "bg-[#008AF6] text-white hover:text-white border-[#008AF6] shadow-[0px_8px_24px_0px_rgba(0,138,246,0.32)]";
        }
      })(),
      ` ${loading ? "opacity-100" : "disabled:opacity-60 opacity-100 shadow-none"}`,
      className
    )
  };
  const props = { ...passthroughProps, ...propsWeControl };

  let {
    as: Component = DEFAULT_BUTTON_TAG,
    children,
    refName = "ref",
    ...ptProps
  } = omit(props, ["unmount", "static"]);

  // This allows us to use `<HeadlessUIComponent as={MyComponent} refName="innerRef" />`
  let refRelatedProps = props.ref !== undefined ? { [refName]: props.ref } : {};
  let resolvedChildren = loading ? (
    <Loader
      type={theme === "primary" || theme === "blue" ? "white" : "primary"}
      className="w-6 h-6"
    />
  ) : (
    children
  );


  return createElement(
    Component,
    Object.assign(
      {},
      omit(ptProps, ["ref"]),
      Component !== Fragment ? refRelatedProps : undefined
    ),

    resolvedChildren
  );
});

export default Button;
