import { IHomeItemDetailInput, IProductDetailModel, ISmallProductModel } from "@types";

export const generateProductLink = (
  product: IProductDetailModel | ISmallProductModel | IHomeItemDetailInput,
  source: string | undefined,
  sourceSlug: string | undefined,
  sourceEntity: string | undefined,
  section: string | undefined,
  sectionEntity: string | undefined,
  sectionSlug: string | undefined,
  q: string | undefined
): string => {
  return `/product/${product.slug ?? product.id}?default_sku=${product.default_sku_id}&source=${
    source ?? ""
  }${sourceSlug ? "&source_slug=" + sourceSlug : ""}${
    sourceEntity ? "&source_entity=" + sourceEntity : ""
  }${section ? "&section=" + section : ""}${
    sectionEntity ? "&section_entity=" + sectionEntity : ""
  }${sectionSlug ? "&section_slug=" + sectionSlug : ""}${q ? "&q=" + q : ""}`;
};
