import { useEffect } from "react";
import getConfig from "next/config";
const { publicRuntimeConfig } = getConfig();

export const useOneSignal = () => {
  useEffect(() => {
    if (publicRuntimeConfig.ONESIGNAL_TOKEN) {
      window.OneSignal = window.OneSignal || [];
      OneSignal.push(function () {
        OneSignal.init({
          appId: publicRuntimeConfig.ONESIGNAL_TOKEN,
          safari_web_id: publicRuntimeConfig.SAFARI_WEB_ID,
          notifyButton: {
            enable: true,
          },
        });
      });

      return () => {
        window.OneSignal = undefined;
      };
    }
  }, []);
};

export const setExternalUserId = (externalUserId, hashId) => {
  if (publicRuntimeConfig.ONESIGNAL_TOKEN) {
    OneSignal.push(function () {
      OneSignal.setExternalUserId(externalUserId);
    });
  }
};
