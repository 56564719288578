import { useAppContext } from "@context/app";
import { convertStringNumber } from "@utils/digits";
import { useTranslations } from "next-intl";
import { useRouter } from "next/router";

interface ProductPriceProps {
  oldPrice: number | null;
  price: number;
  buyLimit: number;
}

const ProductPrice: React.FC<ProductPriceProps> = ({
  oldPrice,
  price,
  buyLimit,
}) => {
  const t = useTranslations("");
  const { locale } = useRouter()
  const { currency, decimal } = useAppContext()

  let priceContent: JSX.Element;
  if (buyLimit) {
    if (oldPrice) {
      priceContent = (
        <div>
          <div dir="ltr" className="text-xs font-normal mt-2 flex space-x-1 flex-wrap items-center w-max">
            <span dir="rtl" className="text-xs text-black font-normal ">{currency}</span>
            <s>{convertStringNumber(locale, oldPrice.toFixed(decimal))}</s>
          </div>
          <div className="text-xs font-normal flex space-x-1 flex-wrap items-center w-max" dir="ltr">
            <span dir="rtl" className="text-xs text-black font-normal ">{currency}</span>
            <span className="font-extrabold text-base text-black">
              {convertStringNumber(locale, price.toFixed(decimal))}
            </span>
          </div>
        </div>
      );
    } else {
      priceContent = (
        <div className="text-xs text-black font-normal mt-2 flex space-x-1 flex-wrap items-center w-max" dir="ltr">
          <span dir="rtl" className="text-xs text-black font-normal ">{currency}</span>
          <span className="font-extrabold text-base">{convertStringNumber(locale, price.toFixed(decimal))}</span>
        </div>
      );
    }
  } else {
    priceContent = <p className="text-[#999] font-bold">{t("client_general_outofstock")}</p>;
  }

  return priceContent;
};

export default ProductPrice;
