import client from "@https/api";
import type {
  IProductDetailModel,
  IProductOutput,
  ICategoryListInput,
  IFilterAndSortInput,
  IProductList,
  IWrappingListInput,
  IWrappingOutput,
  IBrandListInput,
  INotifyListInput,
  IVideoProductListInput,
} from "@types";
import gate from "gate/gate";

const ProductApi = {
  list: (data: IProductOutput = {}, curser?: string) => {
    const url = curser ? `product/${curser}` : "product";
    return gate.post<IProductList, any, never>({
      client,
      url: url,
      data,
    });
  },
  getDetail: (id: string) =>
    gate.get<IProductDetailModel, never, never>({
      client,
      url: `product/${id}`,
    }),
  sortAndFilter: () =>
    gate.get<IFilterAndSortInput, never, never>({
      client,
      url: "product/filter-sort",
    }),
  categoryList: () =>
    gate.get<ICategoryListInput, never, never>({
      client,
      url: "category",
    }),
  subCategoryList: (id: string) =>
    gate.get<ICategoryListInput, never, never>({
      client,
      url: `category/${id}`,
    }),
  wrappingList: (data: IWrappingOutput) =>
    gate.post<IWrappingListInput, IWrappingOutput, never>({
      client,
      url: "order/wrapping",
      data,
    }),
  brandList: (curser?: string) => {
    const url = curser ? `brand/${curser}` : "brand";
    return gate.get<IBrandListInput, never, never>({
      client,
      url: url,
    });
  },
  notify: (skuId: string | number) =>
    gate.get<any, never, never>({
      client,
      url: `notify/add/sku/${skuId}`,
    }),
  cancelNotify: (skuId: string | number, areaId: string | number) =>
    gate.get<any, never, never>({
      client,
      url: `notify/remove/sku/${skuId}/area/${areaId}`,
    }),

  notifyList: (curser?: string) => {
    const url = curser ? `notify/${curser}` : "notify";
    return gate.get<INotifyListInput, never, never>({
      client,
      url: url,
    });
  },
  videoList: (data: IProductOutput = {}, curser?: string) => {
    const url = curser ? `product/${curser}` : "product";
    return gate.post<IVideoProductListInput, any, never>({
      client,
      url: url,
      data,
    });
  },
};

export default ProductApi;
