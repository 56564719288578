import { getCookie } from "@utils/Cookies";
import { useEffect, useState } from "react";

const COOKIE_NAME = "accept_cookies";

export const useAcceptCookies = () => {
  const [acceptedCookies, setAcceptedCookies] = useState(true);

  useEffect(() => {
    if (!getCookie().get(COOKIE_NAME)) {
      setAcceptedCookies(false);
    }
  }, []);

  const acceptCookies = () => {
    setAcceptedCookies(true);
    getCookie().set(COOKIE_NAME, "accepted", {}, 365);
  };

  return {
    acceptedCookies,
    onAcceptCookies: acceptCookies,
  };
};
