import React, { useEffect, useCallback } from 'react';
import { TrashIcon } from '@heroicons/react/solid';
import useIncrementDecrementButton from './useIncrementDecrementButton';

interface IncrementDecrementButtonProps {
  initialQuantity: number;
  onQuantityChange: (quantity: number) => void;
  onRemove: () => void;
  buyLimit?: number;
}

const IncrementDecrementButton: React.FC<IncrementDecrementButtonProps> = ({ initialQuantity, onQuantityChange, onRemove, buyLimit }) => {
  const { quantity, increment, decrement, reset } = useIncrementDecrementButton(initialQuantity, buyLimit);
  useEffect(() => {
    onQuantityChange(quantity);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantity]);

  const handleDecrementOrRemove = useCallback(() => {
    if (quantity > 1) {
      decrement();
    } else {
      reset();
      onRemove();
    }
  }, [quantity, decrement, reset, onRemove]);

  return (
    <div className="flex items-center justify-between h-8 w-[120px] rounded-full border border-mui-primary-main">
      <button
        className="h-full w-10 flex items-center justify-center text-mui-primary-main text-2xl font-medium"
        onClick={handleDecrementOrRemove}
      >
        -
      </button>
      <span className="flex-1 flex items-center justify-center text-base text-mui-text-primary font-normal">
        {quantity}
      </span>
      <button
        disabled={buyLimit ? quantity >= buyLimit : false}
        className="h-full w-10 flex items-center justify-center text-mui-primary-main disabled:cursor-not-allowed disabled:text-mui-divider text-2xl font-medium"
        onClick={increment}
      >
        +
      </button>
    </div>
  );
};

export default IncrementDecrementButton;
