import { getNotifyConfig } from "@utils/helpers";
import { useTranslations } from "next-intl";
import { useState, useCallback } from "react";
import { toast } from "react-toastify";

const useIncrementDecrementButton = (initialQuantity: number, buyLimit?: number) => {
  const [quantity, setQuantity] = useState(initialQuantity);
  const t = useTranslations("");

  const showBuyLimitMsg = useCallback(
    (buy_limit: number) => {
      toast.error(t("client_errors_onlyremaining").replace("@", `${buy_limit}`), getNotifyConfig());
    },
    [t]
  );

  const increment = useCallback(() => {
    setQuantity((prevQuantity) => {
      if (buyLimit && buyLimit <= prevQuantity) {
        showBuyLimitMsg(buyLimit);
        return prevQuantity;
      }
      return prevQuantity + 1;
    });
  }, [buyLimit, showBuyLimitMsg]);

  const decrement = useCallback(() => {
    setQuantity((prevQuantity) => Math.max(prevQuantity - 1, 0));
  }, []);

  const reset = useCallback(() => {
    setQuantity(0);
  }, []);

  return { quantity, increment, decrement, reset };
};

export default useIncrementDecrementButton;
