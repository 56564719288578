import type { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {}

const AddressesIcon: React.FC<Props> = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.0487 15H17.5577C18.4187 15 19.1827 15.551 19.4547 16.368L20.1217 18.368C20.5537 19.663 19.5897 21 18.2247 21H5.77473C4.40973 21 3.44573 19.663 3.87773 18.368L4.54473 16.368C4.81573 15.551 5.58073 15 6.44173 15H8.95173"
        stroke={props.stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 18C12 18 6 13.208 6 8.833C6 5.612 8.686 3 12 3C15.314 3 18 5.612 18 8.833C18 13.208 12 18 12 18Z"
        stroke={props.stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4142 7.58579C14.1953 8.36684 14.1953 9.63317 13.4142 10.4142C12.6332 11.1953 11.3668 11.1953 10.5858 10.4142C9.80474 9.63317 9.80474 8.36684 10.5858 7.58579C11.3668 6.80474 12.6332 6.80474 13.4142 7.58579"
        stroke={props.stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AddressesIcon;
