import { useState, useEffect, useRef } from "react";

interface ParentSize {
  width: number;
  height: number;
}

export default function useParentSize() {
  const parentRef = useRef<HTMLDivElement>(null);

  const [size, setSize] = useState<ParentSize>({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    if (parentRef.current) {
      setSize({
        width: parentRef.current.offsetWidth,
        height: parentRef.current.offsetHeight,
      });
    }
  }, []);

  return [size, parentRef] as const;
}
