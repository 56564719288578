import Accordion from "@elements/accordion";
import { IAreaDetailInput, IAreaInput } from "@types";
import { useRouter } from "next/router";
import React from "react";
import { useEffect, useState } from "react";
import cn from "classnames"

interface ChildAreaProps {
  areas: IAreaInput[]
  action: (area: IAreaDetailInput | undefined) => void
  area?: IAreaDetailInput
}

const ChildArea: React.FC<ChildAreaProps> = ({ areas, action, area: defaultArea }) => {
  const { locale } = useRouter()
  const [selectedArea, setSelectedArea] = useState<undefined | IAreaDetailInput>(defaultArea)
  useEffect(() => {
    action(selectedArea)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedArea])

  useEffect(() => {
    setSelectedArea(defaultArea)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultArea])

  return (
    <div className="w-full">
      {areas.map((area, idx) => (
        <div className={`w-full py-1 border-b`} key={`${area.name}-${area.id}`}>
          <Accordion title={area.name} key={area.id} activeFlag={true}>
            {area.items.map((subArea) => (
              <div
                key={subArea.id}
                className={cn("w-full flex items-center justify-between cursor-pointer px-2 h-14 border-t gap-3", locale?.includes("ar-") ? "flex-row-reverse" : "flex-row")}
                onClick={() => setSelectedArea({ ...subArea, parentName: area.name })}
              >
                <div className=" flex-1 overflow-hidden">
                  <p className={cn("text-sm font-normal text-[#333] whitespace-nowrap overflow-hidden text-ellipsis w-full", locale?.includes("ar-") ? "text-right" : "text-left")}>
                    {subArea.name}
                  </p>
                </div>
                {selectedArea?.id === subArea.id ? <div className="flex justify-end items-center h-6 w-6">
                  <div className={`w-6 h-6 border-2 border-[#008AF6] rounded-full flex justify-center items-center`}>
                    <div className={`w-4 h-4 rounded-full bg-[#008AF6]`}></div>
                  </div>
                </div> : null}
              </div>
            ))}
          </Accordion>
        </div>))}
    </div>
  );
}

export default React.memo(ChildArea);
