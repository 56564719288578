import { IMediaInput, IVariantInput } from "@types";
import { useState, useEffect } from "react";

function getSuitableVariant(variants: IVariantInput[], width: number, webp: boolean) {
  const filteredVariants = variants.filter((x) => x.width && x.webp === webp && x.width >= width);
  return filteredVariants.reduce((prev, current) => {
    if (prev.width && current.width) {
      return prev.width < current.width ? prev : current;
    } else {
      return current;
    }
  }, filteredVariants[0]);
}

export function useImageVariants(media: IMediaInput, width?: number) {
  const [webpImage, setWebpImage] = useState<IVariantInput | undefined>(undefined);
  const [image, setImage] = useState<IVariantInput | undefined>(undefined);

  useEffect(() => {
    if (media.variants) {
      if (width) {
        const webpImage = getSuitableVariant(media.variants, width, true);
        const image = getSuitableVariant(media.variants, width, false);
        setWebpImage(webpImage);
        setImage(image);
      } else {
        const webpImage = getSuitableVariant(media.variants, 0, true);
        const image = getSuitableVariant(media.variants, 0, false);
        setWebpImage(webpImage);
        setImage(image);
      }
    }
  }, [width, media.variants]);

  return { webpImage, image };
}
