import EmptyCart from "@svg/redesign/EmptyCart";

interface EmptyCartPreviewProps {

}

const EmptyCartPreview: React.FC<EmptyCartPreviewProps> = () => {
  return (
    <div className="w-full h-full flex flex-col items-center justify-center gap-2">
      <EmptyCart className="mb-4" />
      <p className=" text-2xl font-normal text-black">Your Cart is Empty</p>
      <p className=" text-black text-opacity-40 text-base font-medium">Looks Like you haven’t made your choice yet</p>
    </div>
  );
}

export default EmptyCartPreview;