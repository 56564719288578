import type { ApiResponse, RequestMeta } from "@types";
import type { Middleware } from "swr";

const isRequestMeta = (obj: any) => !!obj && typeof obj === "object" && obj.hasOwnProperty("key");

export const serialize: Middleware = (useSWRNext) => {
  return (key, fetcher, config) => {
    let req: any = null;

    if (isRequestMeta(key)) {
      req = key;
      key = req.key();
    } else if (Array.isArray(key) && key.length > 0 && isRequestMeta(key[0])) {
      const [_req, ...others] = key;
      req = _req;
      key = JSON.stringify([req.key(), ...others]);
    } else {
      key = JSON.stringify(key);
    }

    return useSWRNext(key, fetcher && req ? () => fetcher(req) : fetcher, config);
  };
};

export const fetcher: <T, D, P>(
  req: RequestMeta<ApiResponse<T>, D, P>
) => Promise<ApiResponse<T>> = async (req) => {
  if (isRequestMeta(req)) return req.fetch();
  return Promise.reject("invalid request handler");
};
