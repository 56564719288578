import type { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {}

const BagIcon: React.FC<Props> = (props) => {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9998 21H5.99976C4.27776 21 2.90876 19.553 3.00476 17.834L3.61876 6.778C3.73676 4.658 5.48976 3 7.61276 3H16.3878C18.5108 3 20.2638 4.658 20.3818 6.778L20.9958 17.833C21.0908 19.553 19.7218 21 17.9998 21Z"
        stroke={props.stroke || "#333"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 7.75C16 9.959 14.209 11.75 12 11.75C9.791 11.75 8 9.959 8 7.75"
        stroke={props.stroke || "#333"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BagIcon;
