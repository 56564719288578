import { useAppContext } from "@context/app";
import { IOrderCheckCartOutput, IOrderGiftCartOutput } from "@types";
import { orderApi } from "gate";
import { useEffect } from "react";

function useCart() {
  const { cart, setUserCart } = useAppContext();

  useEffect(() => {
    updateCart();
  }, []);

  const preparingCart = () => {
    const checkCartValue: IOrderCheckCartOutput = {
      items: [],
    };
    const items = [...cart];

    for (const sku of items) {
      let map = new Array(sku.qty);
      const gift: IOrderGiftCartOutput[] = [];

      for (const [index] of map.entries()) {
        const target = sku.gifts[index] || {};
        gift.push({
          wrapping: target.wrapping?.id || null,
          card: target.card?.from && target.card?.to && target.card?.message ? target.card : null,
          customtext: sku.customText ? sku.customText[index]?.text : "",
        });
      }

      checkCartValue.items.push({
        id: parseInt(sku.id as any),
        count: sku.qty,
        gift,
      });
    }

    return checkCartValue;
  };

  const updateCart = async () => {
    const preparedCart = preparingCart();
    if (preparedCart?.items?.length) {
      try {
        const res = await orderApi.checkCart(preparedCart).fetch();
        const _cart = [...cart];
        for (const sku of _cart) {
          const target = res.data.cart.find((s) => s.id === sku.id);
          if (target) {
            sku.skuName = target.sku.name;
            sku.productName = target.sku.product?.name ?? sku.productName;
          }
        }
        setUserCart(_cart);
      } catch (error) {}
    }
  };
  return { updateCart };
}

export default useCart;
