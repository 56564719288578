import useCartPreview from "./useCartPreview";
import { Popover, Transition } from "@headlessui/react";
import { Fragment } from "react";
import cn from "classnames";
import { convertNumber } from "@utils/digits";
import BagIcon from "@svg/BagIcon";
import Header from "./components/Header/Header";
import CartItems from "./components/CartItems/CartItems";
import Footer from "./components/Footer/Footer";
import EmptyCartPreview from "./components/EmptyCartPreview/EmptyCartPreview";

interface CartPreviewProps {
  className?: string;
}

const CartPreview: React.FC<CartPreviewProps> = ({ className }) => {
  const {
    cart,
    locale,
    ItemsQty,
    totalPrice,
    subtotalPrice,
    totalDiscount,
    goToCartView,
    removeItemFromCart,
    changeQty,
  } = useCartPreview();

  return (
    <Popover className={cn("relative", className)} as="nav">
      {({ open, close }) => (
        <Fragment>
          <Transition as={Fragment} {...transitionProps}>
            <Popover.Overlay className="fixed inset-0 backdrop-brightness-50 z-[60]" />
          </Transition>
          <Popover.Button className="relative z-[20] focus:outline-none h-full w-full border rounded-xl" aria-label="Cart">
            <BagIcon className="cursor-pointer m-auto" />
            {ItemsQty > 0 && (
              <div className="w-5 h-5 rounded-full bg-brandBlue absolute flex justify-center items-center top-6 right-0">
                <span className="text-white font-normal text-xs">
                  {convertNumber(locale, ItemsQty)}
                </span>
              </div>
            )}
          </Popover.Button>

          <Transition as={Fragment} {...panelTransitionProps(locale)}>
            <Popover.Panel className={`flex flex-col gap-4 fixed h-screen top-0 z-[60] ${locale?.includes("ar-") ? "left-0" : "right-0"} w-screen overflow-hidden max-w-[450px] bg-white transform px-8 py-5 `}>
              <Header close={close} cartLength={ItemsQty} locale={locale} />
              {ItemsQty ? <CartItems cart={cart} locale={locale} removeItemFromCart={removeItemFromCart} changeQty={changeQty} close={close} /> : <EmptyCartPreview />}
              <Footer totalPrice={totalPrice} subtotalPrice={subtotalPrice} totalDiscount={totalDiscount} cartLength={ItemsQty} goToCartView={goToCartView} close={close} />
            </Popover.Panel>
          </Transition>
        </Fragment>
      )}
    </Popover>
  );
}

export default CartPreview;

const transitionProps = {
  enter: "ease-out duration-300",
  enterFrom: "opacity-0",
  enterTo: "opacity-100",
  leave: "ease-in duration-500",
  leaveFrom: "opacity-100",
  leaveTo: "opacity-100",
};

const panelTransitionProps = (locale: string | undefined) => ({
  enter: "transition ease-out duration-500",
  enterFrom: `opacity-100 ${locale?.includes("ar-") ? "-translate-x-full" : "translate-x-full"}`,
  enterTo: `opacity-100 ${locale?.includes("ar-") ? "translate-x-0" : "translate-x-0"}`,
  leave: "transition ease-in duration-500",
  leaveFrom: `opacity-100 ${locale?.includes("ar-") ? "translate-x-0" : "translate-x-0"}`,
  leaveTo: `opacity-100 ${locale?.includes("ar-") ? "-translate-x-full" : "translate-x-full"}`
})
