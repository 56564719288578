import auth from "@api/auth";
import type { ValidationError } from "@types";
import { getCookie } from "@utils/Cookies";
import { useEffect, useRef } from "react";
import { ToastOptions } from "react-toastify";
import { COOKIE_ACCESS_TOKEN_KEY, COOKIE_LOGIN_TOKEN_KEY } from "./constants";
import Router from "next/router";

export function isServer() {
  return typeof window === "undefined";
}
export function addFormServerErrors<T>(
  errors: ValidationError<T>[],
  setError: (fieldName: keyof T, error: { type: string; message: string }) => void
) {
  return errors.forEach((error) => {
    error.members.forEach((m) => {
      setError(m, {
        type: "server",
        message: error.message,
      });
    });
  });
}

export function clip(a: number, min: number, max: number) {
  return Math.min(Math.max(a, min), max);
}
export function getBasePath(locales: string[]) {
  if (!isServer()) {
    const { pathname } = window.location;
    const split = pathname.split("/");
    const base = window.location.protocol + "//" + window.location.host;
    if (split.length > 1 && locales.findIndex((f) => f === split[1]) >= 0) {
      return base + "/" + split[1];
    } else return base;
  }
}

export function updateCart() {
  const currentCart = window.localStorage.getItem("DABDOOB_CART");
  const data = currentCart ? [...JSON.parse(currentCart)] : [];
  return [...data];
}

export async function CheckIsUserLoginAndHasAccess(callback?: () => void) {
  const Cookies = getCookie();
  const accessToken = Cookies.get(COOKIE_ACCESS_TOKEN_KEY);
  const loginToken = Cookies.get(COOKIE_LOGIN_TOKEN_KEY);
  if (!loginToken) {
    callback?.();
  }
  if (!accessToken) {
    const res = await auth.deviceAuth().fetch();
    Cookies.set(COOKIE_ACCESS_TOKEN_KEY, res.data.access_token, {}, 365 * 2);
  }
}

export const useIsMount = () => {
  const isMountRef = useRef(true);
  useEffect(() => {
    isMountRef.current = false;
  }, []);
  return isMountRef.current;
};

export function getNotifyConfig(config?: ToastOptions): ToastOptions {
  const defaults: ToastOptions = {
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    draggable: true,
    pauseOnHover: true,
    theme: "colored",
  };
  return Object.assign(defaults, config);
}

export const setRoute = (object: { [key: string]: any }, path: string) => {
  let paramsQuery = {};
  let stringParams = "";
  Object.entries(object).forEach((d) => {
    if (d[1] !== undefined) {
      paramsQuery = { ...paramsQuery, [d[0]]: d[1] };
      if (!stringParams.length) {
        stringParams = `?${d[0]}=${d[1]}`;
      } else {
        stringParams = `${stringParams}&${d[0]}=${d[1]}`;
      }
    }
  });
  Router.push(decodeURIComponent(`${path}${stringParams}`), undefined, {
    shallow: true,
  });
  return paramsQuery;
};

export const calcBodyLength = (str: string) => {
  return isServer() ? Buffer.from(str).length : new Blob([str]).size;
};
