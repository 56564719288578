import Image from "next/image";
import Link from "next/link";
import PriceDisplay from "../PriceDisplay/PriceDisplay";
import IncrementDecrementButton from "../IncrementDecrementButton/IncrementDecrementButton";
import { CartItemModel } from "@types";
import { convertNumber } from "@utils/digits";

interface CartItemsProps {
  cart: CartItemModel[];
  locale: string | undefined;
  removeItemFromCart: (item: CartItemModel) => void;
  changeQty: (id: number | string, count: number) => void;
  close: () => void;
}

const CartItems: React.FC<CartItemsProps> = ({ cart, locale, removeItemFromCart, changeQty, close }) => {
  return (
    <div className="w-full flex-1 overflow-auto relative no-scrollbar flex flex-col gap-5">
      {cart.map((sku) => (
        <div className="w-full flex items-center gap-3 h-max" key={sku.id}>
          <div className="w-[80px] h-[80px] rounded-2xl border border-[#EEE]">
            <div className="w-full h-full relative rounded-2xl overflow-hidden" onClick={close}>
              <Link href={`/product/${sku.productSlug ?? sku.productId}?default_sku=${sku.id}`} passHref prefetch={false}>
                <a>
                  <Image src={sku.image} layout="fill" alt="" className="rounded-2xl" />
                </a>
              </Link>
            </div>
          </div>
          <div className="flex-1 h-full flex flex-col justify-between gap-3">
            <p className="w-full line-clamp-2 text-sm text-mui-text-primary">
              {convertNumber(locale, sku.productName)}
            </p>
            <IncrementDecrementButton initialQuantity={sku.qty} onQuantityChange={(count) => changeQty(sku.id, count)} onRemove={() => removeItemFromCart(sku)} buyLimit={sku.buy_limit} />
          </div>
          <div className="flex gap-2">
            {sku.discount_percentage ? <p className=" px-3 py-1 bg-mui-secondary-main rounded-full text-[13px] text-white font-medium">{`${sku.discount_percentage}`}</p> : null}
            <PriceDisplay price={sku.price} />
          </div>
        </div>
      ))}
    </div>
  )
};

export default CartItems