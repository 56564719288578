import client from "@https/api";
import type {
  IOrderCalculateInput,
  IOrderCalculateOutput,
  IOrderCheckCartInput,
  IOrderCheckCartOutput,
  IOrderDetailInput,
  IOrderListInput,
  IOrderStatus,
  IOrderSubmitInput,
  IOrderTabListInput,
} from "@types";
import gate from "gate/gate";

const orderApi = {
  checkCart: (data: IOrderCheckCartOutput) =>
    gate.post<IOrderCheckCartInput, IOrderCheckCartOutput, never>({
      client,
      url: "order/cart",
      data,
    }),
  calculate: (data: IOrderCalculateOutput) =>
    gate.post<IOrderCalculateInput, IOrderCalculateOutput, never>({
      client,
      url: "order/calculate",
      data,
    }),
  submit: (data: IOrderCalculateOutput) =>
    gate.post<IOrderSubmitInput, IOrderCalculateOutput, never>({
      client,
      url: "order/submit",
      data,
    }),
  retry: (data: { payment_method: number }, orderId: string) =>
    gate.post<IOrderSubmitInput, { payment_method: number }, never>({
      client,
      url: `user/order/${orderId}/retry`,
      data,
    }),
  list: (status: IOrderStatus = "all", curser?: string) => {
    const url = curser ? `user/order/list/${status}/${curser}` : `user/order/list/${status}`;
    return gate.get<IOrderListInput, never, never>({
      client,
      url: url,
    });
  },
  tabs: () =>
    gate.get<IOrderTabListInput, never, never>({
      client,
      url: `user/order/tabs`,
    }),
  getDetail: (orderId: string) =>
    gate.get<IOrderDetailInput, never, never>({
      client,
      url: `user/order/${orderId}`,
    }),
};

export default orderApi;
