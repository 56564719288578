export const linkGenerator = (
  action: string,
  query?: {
    source?: string;
    sourceEntity?: string;
    sourceSlug?: string;
    section?: string;
    sectionEntity?: string;
    sectionSlug?: string;
    target_slug?: string;
  }
) => {
  const id = action.split("/").pop() ?? "0";

  switch (true) {
    case action.includes("openAttributevalue"):
      return generateAttributeValueLink(id);
    case action.includes("openCategories"):
      return "/categories";
    case action.includes("openCollection"):
      return generateCollectionLink(id);
    case action.includes("openCategory"):
      return generateCategoryLink(id);
    case action.includes("openProduct"):
      return generateProductLink(query);
    case action.includes("openBrands"):
      return "/brands";
    case action.includes("openBrand"):
      return generateBrandLink(id);
    case action.includes("openURLinApp"):
      return decodeBase64(id);
    case action.includes("openURL"):
      return decodeBase64(id);
    case action.includes("openVideoSku"):
      return "/videos";
    default:
      return "/";
  }
};

const generateAttributeValueLink = (id: string) => `/product?attributes_id=${id}`;

const generateCollectionLink = (id: string) => `/product?collection_id=${id}`;

const generateCategoryLink = (id: string) => `/product?category_id=${id}`;

const generateProductLink = (query: any) => {
  const baseLink = `/product/${query?.target_slug}?source=${query?.source ?? ""}`;
  const sourceSlug = query?.sourceSlug ? `&source_slug=${query.sourceSlug}` : "";
  const sourceEntity = query?.sourceEntity ? `&source_entity=${query.sourceEntity}` : "";
  const section = query?.section ? `&section=${query.section}` : "";
  const sectionEntity = query?.sectionEntity ? `&section_entity=${query.sectionEntity}` : "";
  const sectionSlug = query?.sectionSlug ? `&section_slug=${query.sectionSlug}` : "";
  return `${baseLink}${sourceSlug}${sourceEntity}${section}${sectionEntity}${sectionSlug}`;
};

const generateBrandLink = (id: string) => `/product?brand_id=${id}`;

const decodeBase64 = (id: string) => Buffer.from(`${id}`, "base64").toString();
