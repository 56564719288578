import ProgressBar from "../ProgressBar/ProgressBar";
import useFreeDelivery from "./useFreeDelivery";

const FreeDelivery: React.FC = () => {
  const { currency, freeShipping, totalPrice, remaining, isFree, cart, t, locale } = useFreeDelivery();

  return (
    freeShipping && cart.length ? (
      <div className="w-full max-w-[771px] bg-[#F8F8F8] rounded-xl p-3 flex flex-col gap-3 items-center mx-auto">
        {isFree ? <p className="text-mui-success-main text-base font-bold">{t("client_productdetails_freedeliveryenjoy")}</p> : <bdi className="text-mui-grey-800 text-base font-normal">{t("client_productdetails_freedeliveryremaining").replace("@", locale?.includes("ar-") ? `${remaining} ${currency}` : `${currency} ${remaining}`)}</bdi>}
        <div className="w-full px-5">
          <ProgressBar maxValue={freeShipping} value={totalPrice} />
        </div>
      </div>
    ) : null
  );
};

export default FreeDelivery;
