import Button from "@elements/button";
import Image from "next/image";
import { useEffect, useState, useCallback } from "react";
import { BsArrowLeft } from "react-icons/bs";
import Modal from "./modal";
import { authApi, commonApi } from "gate";
import {
  IAreaDetailInput,
  IAreaInput,
  ICountryInput,
  ICountryListInput,
} from "@types";
import { getCookie } from "@utils/Cookies";
import {
  COOKIE_AREA_KEY,
  COOKIE_COUNTRY_KEY,
  COOKIE_LOCALE_KEY,
  COOKIE_ACCESS_TOKEN_KEY,
  countryBreakPoints,
  NEXT_LOCALE_KEY,
  COOKIE_IS_OLD_USER,
} from "@utils/constants";
import Router, { useRouter } from "next/router";
import { useAppContext } from "@context/app";
import Loading from "assets/lottie/loading";
import AreaView from "@elements/areaView";
import { Swiper, SwiperSlide } from "swiper/react";
import { segmentEvent } from "@utils/segment";
import { useTranslations } from "next-intl";
import ImagePrepare from "@elements/imagePrepare";
import useEvents from "@hooks/useEvents";
import useGTM from "@hooks/useGTM";

interface CountryProps {
  onClose: (data: boolean) => void;
  isOpen: boolean;
  hasCancel: boolean;
}

const languageList = [
  { title: "English", slug: "en" },
  { title: "Arabic", slug: "ar" },
];

const sourcePage = {
  from_page: "onboarding",
  from_entity: "",
  from_entity_slug: "",
  from_page_section: "",
  from_page_section_entity: "",
  from_page_section_slug: "",
}

const Country: React.FC<CountryProps> = ({ onClose, isOpen, hasCancel }) => {
  const { areaPageViewed, countryPageViewed, openMainMenu } = useEvents()
  const { asPath, locale, query, pathname } = useRouter();
  const { source } = query
  const [lang, setLang] = useState(locale?.split("-")[0] || "en");
  const [view, setView] = useState<"country" | "area">("country");
  const [countryEvent, setCountryEvent] = useState(false)
  const [areaEvent, setAreaEvent] = useState(false)
  const [countries, setCountriesList] = useState<ICountryInput[]>([]);
  const [areaList, setAreaList] = useState<IAreaInput[]>([]);
  const [country, setSelectedCountry] = useState<ICountryInput | undefined>();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<ICountryListInput | undefined>(undefined)
  const { setUserCountry, setUserArea, user, eventProperties, setUserFreeShipping } = useAppContext();
  const Cookies = getCookie();
  const isOldUser = Cookies.get(COOKIE_IS_OLD_USER)
  const t = useTranslations()
  const validPathForEvent = ["/", "/settings", "/offers", "/wallet"]
  const { pushToDataLayer } = useGTM();


  useEffect(() => {
    getCountries()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getCountries = async () => {
    if (Cookies.get(COOKIE_ACCESS_TOKEN_KEY)) {
      commonApi.getCountryList().fetch().then(res => {
        setData(res.data)
      }).catch(err => { console.log(err) })
    }
    else {
      await authApi.deviceAuth().fetch().then((res) => {
        const token = res.data.access_token || "";
        Cookies.set(COOKIE_ACCESS_TOKEN_KEY, token, {}, 365 * 2);
      }).finally(() => {
        commonApi.getCountryList().fetch().then(res => {
          setData(res.data)
        }).catch(err => { console.log(err) })
      })
    }
  }
  useEffect(() => {
    const setDependencies = async () => {
      try {
        if (data?.items) {
          const _countries = data.items;
          setCountriesList(_countries);
          if (locale?.split("-")[1]) {
            const defaultCountry = _countries.find(c => c.code2 === locale?.split("-")[1])
            setSelectedCountry(defaultCountry ?? _countries[0]);
            if (defaultCountry) {
              Cookies.set(NEXT_LOCALE_KEY, lang, {}, 365 * 2);
              Cookies.set(COOKIE_LOCALE_KEY, lang, {}, 365 * 2);
              setView("area");
              const res = await commonApi.getAreaList(defaultCountry.id).fetch();
              setAreaList(res.data.items);
            }
          }
          else {
            setSelectedCountry(_countries[0]);
          }
        }
      } catch (error) {
        console.log(error)
      } finally {
        setIsLoading(false);
      }

    }

    if (data?.items) {
      setDependencies()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const getAreaList = useCallback(async () => {
    setIsLoading(true);
    setAreaList([]);
    if (country) {
      try {
        const res = await commonApi.getAreaList(country.id).fetch();
        setAreaList(res.data.items);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }
  }, [country]);
  let eventSource: any;
  if (isOldUser) {
    if (source === "share") {
      eventSource = { ...sourcePage, from_page: "share" };
    } else {
      eventSource = { ...eventProperties };
    }
  } else {
    eventSource = { ...sourcePage };
  }
  const handelSubmit = (area: IAreaDetailInput | IAreaInput) => {
    const selectedLang = lang === "en" ? `en-${country?.code2}` : `ar-${country?.code2}`
    Cookies.set(NEXT_LOCALE_KEY, selectedLang, {}, 365 * 2);
    Cookies.set(COOKIE_LOCALE_KEY, selectedLang, {}, 365 * 2);
    Cookies.set(COOKIE_COUNTRY_KEY, country?.code2, {}, 365 * 2);
    Cookies.set(COOKIE_AREA_KEY, country?.area_type === 1 ? area.default_area : area.id, {}, 365 * 2);
    setUserCountry(country);
    setUserFreeShipping(country?.freeshipping || null)
    setUserArea(area)
    if (validPathForEvent.includes(pathname) || !isOldUser || source === "share") {
      segmentEvent("Country Changed", {
        country_id: country?.id,
        country_slug: country?.slug,
        area_id: area.id,
        area_slug: area.slug,
        language: lang === "en" ? "English" : "Arabic",
        user_id: user.data?.id || -1,
        ...eventSource
      })
      pushToDataLayer("Country Changed", {
        country_id: country?.id,
        country_slug: country?.slug,
        area_id: area.id,
        area_slug: area.slug,
        language: lang === "en" ? "English" : "Arabic",
        user_id: user.data?.id || -1,
        ...eventSource
      })
    }

    onClose(false);
    Router.replace(asPath, asPath, {
      locale: selectedLang,
    }).then(() => {
      if (country && area) {
        openMainMenu(country.id, country.slug, area.id, area.slug, lang as any)
      }
      Router.reload();
    });
  };


  useEffect(() => {
    if (view === "country" && !countryEvent) {
      countryPageViewed()
      setCountryEvent(true)
    }
    if (view === "area" && lang && country && !areaEvent) {
      areaPageViewed(country.id, country.slug, lang as any)
      setAreaEvent(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view])


  const handelNextStep = () => {
    if (country) {
      if (country.area_type === 0 && country.default_area) {
        handelSubmit(country.default_area)
      } else {
        const selectedLang = lang === "en" ? `en-${country?.code2}` : `ar-${country?.code2}`
        Cookies.set(NEXT_LOCALE_KEY, selectedLang, {}, 365 * 2);
        Cookies.set(COOKIE_LOCALE_KEY, selectedLang, {}, 365 * 2);
        Router.replace(asPath, asPath, {
          locale: selectedLang,
        })
        setView("area");
        getAreaList();
      }
    }
  }

  return (
    <Modal open={isOpen} onClose={() => onClose(false)} cancelable={hasCancel}>
      {isLoading && <Loading isCenter />}
      <div className="md:w-[480px] sm:w-screen xs:w-screen w-screen max-w-[440px] min-h-[480px] h-max  bg-white rounded-lg p-4">
        <div className=" w-full flex flex-wrap mb-5 items-center">
          <p className="w-10 text-[#999]">
            {view === "area" ? (
              <BsArrowLeft
                size={20}
                className=" cursor-pointer"
                onClick={() => setView("country")}
              />
            ) : null}
          </p>
          <div className="flex-1 font-normal text-xl text-[#333] text-center flex items-center justify-center">
            {view === "area" ? (
              <>
                <div className="w-6 h-6 relative overflow-hidden mx-2 rounded-full">
                  <Image
                    alt={country?.name || "dabdoob"}
                    src={country?.image.url || ""}
                    layout="fill"
                  />
                  <span>{t("client_area_selectarea")}</span>
                </div>
                <span>{t("client_area_selectarea")}</span>
              </>
            ) : (
              ""
            )}
          </div>
          <p className="w-10 text-[#999] flex justify-end"></p>
        </div>

        {view === "country" && (
          <div className=" w-full">
            <div className=" w-[163px] h-[179px] relative m-auto">
              <Image
                src="/imgs/country-big.png"
                layout="fill"
                alt="country"
                objectFit="cover"
                priority
                sizes="100vw"
              />
            </div>

            <div className="w-full mt-5">
              {!hasCancel ? (
                <p className=" text-xl font-extrabold text-[#333] text-center">
                  Welcome to Dabdoob!
                </p>
              ) : null}
              <p className=" font-normal text-base text-[#333] mt-3">
                Please choose your language and country:
              </p>
            </div>
            <div className=" w-full mt-5 flex flex-wrap justify-center">
              {languageList.map((l) => (
                <div className="p-2 w-36 h-[52px] " key={l.slug}>
                  <div
                    onClick={() => setLang(l.slug)}
                    className={`w-full h-full flex items-center justify-center rounded-full cursor-pointer ${lang === l.slug
                      ? "bg-[rgba(0,138,246,0.12)] text-[#008AF6] border-2 border-[#008AF6]"
                      : " bg-white text-[#999] border border-[#eee]"
                      }`}
                  >
                    <p className=" font-normal text-sm">{l.title}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className=" w-full mt-5 ">
              <div className="overflow-auto min-h-[130px] relative">
                {countries.length ? (
                  <>
                    <div
                      className={` absolute w-[72px] h-[72px]  rounded-full  border-2 border-[#008AF6] sm:left-[calc(50%-36px)] xs:left-[calc(50%-36px)]  mxs:left-[calc(50%-36px)] left-[calc(50%-36px)] top-2 z-10`}
                    ></div>
                    <Swiper
                      loop={true}
                      noSwiping={true}
                      noSwipingSelector="button"
                      spaceBetween={1}
                      slidesPerView={"auto"}
                      centeredSlides={true}
                      slideToClickedSlide={true}
                      initialSlide={countries.findIndex(
                        (c) => c.id === country?.id
                      )}
                      roundLengths={true}
                      loopAdditionalSlides={2}
                      breakpoints={countryBreakPoints}
                      className={"swiper-padding"}
                      onSlideChange={(e) => {
                        setSelectedCountry(countries[e.realIndex]);
                      }}
                    >
                      {countries.map((c) => (
                        <SwiperSlide key={c.id}>
                          {() => (
                            <div className="" key={`country-${c.id}`}>
                              <div
                                className={`w-[72px] h-[72px] flex items-center justify-center rounded-full m-auto`}
                              >
                                <div className=" w-14 h-14 relative overflow-hidden rounded-full cursor-pointer">
                                  {country?.id !== c.id && (
                                    <div className="w-full h-full absolute inset-0 bg-gray-100 bg-opacity-60 z-20" />
                                  )}
                                  <ImagePrepare media={c.image} alt={c.name} width={56} />
                                </div>
                              </div>

                              {country?.id === c.id && (
                                <div className={`w-full mt-2`}>
                                  <p className=" cursor-pointer w-full text-center whitespace-normal font-medium text-xs">
                                    {c.name}
                                  </p>
                                </div>
                              )}
                            </div>
                          )}
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </>
                ) : null}
              </div>
            </div>
            <div className="w-full mt-3 mb-2">
              <Button
                size="slim"
                className="m-auto w-48 h-12 font-extrabold text-base rounded-full"
                isDisabled={isLoading}
                onClick={handelNextStep}
              >
                {t("client_buttons_continue")}
              </Button>
            </div>
          </div>
        )}
        {view === "area" && (
          <>
            <div className="w-full  rounded-xl  overflow-auto h-[430px]">
              <AreaView areaType={country?.area_type} areas={areaList} action={handelSubmit} />
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default Country;
