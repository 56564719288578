import type { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> { }

const ChevronLeftFilled: React.FC<Props> = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="17" height="24" viewBox="0 0 17 24" fill="none">
      <path d="M8.20492 7.41L6.79492 6L0.794922 12L6.79492 18L8.20492 16.59L3.62492 12L8.20492 7.41Z" fill="black" fillOpacity="0.87" />
    </svg>
  );
};

export default ChevronLeftFilled;
