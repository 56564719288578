import Button from "@elements/button"
import Modal from "./modal"
import { useTranslations } from "next-intl"
import LocationBear from "@svg/locationBear"
import useEvents from "@hooks/useEvents"
import { useEffect } from "react"
import { useRouter } from "next/router"

interface LocationPermissionProps {
  onConfirm: () => void
  onReject: () => void
  isOpen: boolean
  isCancelable: boolean
}

const LocationPermission: React.FC<LocationPermissionProps> = ({ onConfirm, onReject, isCancelable, isOpen }) => {
  const t = useTranslations("");
  const { locale } = useRouter()

  const { permissionPageViewed } = useEvents()

  useEffect(() => {
    permissionPageViewed()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Modal open={isOpen} onClose={() => { }} cancelable={isCancelable}>
      <div className="md:w-[480px] sm:w-screen xs:w-screen w-screen max-w-[440px]  md:h-max h-[100dvh]  bg-white rounded-lg md:py-10 md:px-5 p-6 flex flex-col items-center justify-center gap-8">
        <div className=" w-max mx-auto">
          <LocationBear />
        </div>
        <div className="w-full">
          <p dir={locale?.includes("ar") ? "rtl" : "ltr"} className=" text-[#333] font-normal text-sm text-center leading-6">
            {t("client_location_detection_title")}
          </p>
        </div>
        <div className="w-full">
          <div className="w-full flex md:flex-row flex-col gap-4 flex-wrap justify-center">
            <Button
              className="m-auto md:flex-1 flex-none w-full h-12 font-bold text-[15px] rounded-full"
              onClick={() => onConfirm()}
            >
              {t("client_buttons_confirm_detect_location")}
            </Button>
            <Button
              className="m-auto md:flex-1 flex-none w-full h-12 font-bold text-[15px] rounded-full"
              theme="lightBlue"
              onClick={() => onReject()}
            >
              {t("client_buttons_manually_detect_location")}
            </Button>

          </div>
        </div>
      </div>
    </Modal>
  );
}

export default LocationPermission;