import type { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {}

const BurgerIcon: React.FC<Props> = (props) => {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.89648 9.78009H16.0379"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.89648 14.2199H13.6589"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="21.0039"
        y="21.0038"
        width="18.0075"
        height="18.0075"
        rx="5"
        transform="rotate(180 21.0039 21.0038)"
        stroke="#333333"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BurgerIcon;
