import cn from "classnames";
import React, { Fragment, useRef } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { BsX } from "react-icons/bs";
import { useRouter } from "next/router";

interface Props {
  onClose: () => void;
  open: boolean;
  cancelable: boolean;
  size?: "small" | "mid" | "big" | "full";
  withoutBackground?: boolean;
  isCenter?: boolean
}

const Modal: React.FC<Props> = ({
  size,
  open,
  cancelable,
  onClose,
  children,
  withoutBackground,
  isCenter
}) => {
  const { locale } = useRouter();
  const _onClose = () => {
    if (cancelable) onClose();
  };
  const completeRef = useRef(null);

  let alignClass = isCenter ? "align-middle" : "md:align-middle align-bottom";
  let sizeClass;
  if (size === "full") {
    sizeClass = "max-w-screen";
  } else if (size === "big") {
    sizeClass = "max-w-5xl";
  } else if (size === "mid") {
    sizeClass = "max-w-3xl";
  } else if (size === "small") {
    sizeClass = "max-w-lg";
  } else {
    sizeClass = "w-[max-content]";
  }

  let className = cn(
    alignClass,
    sizeClass,
    locale?.includes("ar-") ? "!font-arabic" : "!font-arabic",
    "inline-block relative w-max transition-all transform bg-transparent shadow-xl rounded-[1.1875rem]"
  );


  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        initialFocus={completeRef}
        as="div"
        className="fixed inset-0 z-[60] overflow-y-auto height-screen"
        onClose={_onClose}
        aria-label="modal view"
        aria-labelledby="labelmodal"
      >
        <div className="h-full relative px-0 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className={`fixed inset-0 ${withoutBackground
                ? "bg-black bg-opacity-10"
                : "bg-black bg-opacity-40"
                } `}
            />
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-full align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={className} >
              {cancelable ? (
                <div className="absolute right-8 top-3 flex justify-end ">
                  <button
                    className="w-3 h-3 focus:outline-none  text-[#333]"
                    onClick={() => {
                      _onClose();
                    }}
                  >
                    <div className=" w-10 h-10 rounded-full overflow-hidden flex justify-center items-center bg-white">
                      <BsX size={30} />
                    </div>
                  </button>
                </div>
              ) : null}
              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
export default Modal;
