// // components/Button.tsx

// import classNames from 'classnames';
// import React from 'react';

// interface ButtonProps {
//   label: string;
//   theme: 'primary' | 'secondary' | 'success' | 'danger' | 'primaryOutline';
//   size?: 'small' | 'medium' | 'large';
//   onClick: () => void;
//   disabled?: boolean;
//   loading?: boolean;
// }

// const Button: React.FC<ButtonProps> = ({ label, theme, size, onClick, disabled = false, loading = false }) => {
//   const baseStyles = 'w-full h-14 rounded-full font-normal  transition-all ease-linear duration-200';
//   let colorStyles = '';
//   let disabledStyles = '';
//   let sizeStyles = '';

//   switch (theme) {
//     case 'primary':
//       colorStyles = 'bg-mui-primary-main text-white hover:bg-blue-500';
//       disabledStyles = 'bg-blue-300 text-white cursor-not-allowed';
//       break;
//     case 'secondary':
//       colorStyles = 'bg-gray-500 text-white hover:bg-gray-700';
//       disabledStyles = 'bg-gray-300 text-white cursor-not-allowed';
//       break;
//     case 'success':
//       colorStyles = 'bg-green-500 text-white hover:bg-green-700';
//       disabledStyles = 'bg-green-300 text-white cursor-not-allowed';
//       break;
//     case 'danger':
//       colorStyles = 'bg-red-500 text-white hover:bg-red-700';
//       disabledStyles = 'bg-red-300 text-white cursor-not-allowed';
//       break;
//     case 'primaryOutline':
//       colorStyles = 'border border-[#2196F3CC] text-mui-primary-main  bg-white hover:bg-blue-50';
//       disabledStyles = 'border border-blue-300 text-blue-300 bg-white cursor-not-allowed';
//       break;
//     default:
//       colorStyles = 'bg-mui-primary-main text-white hover:bg-blue-500';
//       disabledStyles = 'bg-blue-300 text-white cursor-not-allowed';
//   }

//   switch (size) {
//     case 'small':
//       sizeStyles = 'text-sm';
//       break;
//     case 'medium':
//       sizeStyles = 'text-base';
//       break;
//     case 'large':
//       sizeStyles = 'text-xl';
//       break;
//     default:
//       sizeStyles = 'text-base';
//   }

//   const loadingStyles = 'cursor-wait';

//   return (
//     <button
//       className={classNames(baseStyles, disabled ? disabledStyles : colorStyles, loading ? loadingStyles : '', sizeStyles)}
//       onClick={onClick}
//       disabled={disabled || loading}
//     >
//       {loading ? 'Loading...' : label}
//     </button>
//   );
// };

// export default Button;

// components/Button.tsx
// components/Button.tsx

import * as React from 'react';
import {
  Button as BaseButton,
  ButtonOwnerState,
  ButtonProps,
} from '@mui/base/Button';
import classNames from 'classnames';

interface CustomButtonProps extends ButtonProps {
  label: string;
  theme: 'primary' | 'secondary' | 'success' | 'danger' | 'primaryOutline';
  size?: 'small' | 'medium' | 'large';
  loading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, CustomButtonProps>(function Button(
  { label, theme, size = 'medium', loading = false, ...props },
  ref
) {
  const getColorStyles = (theme: string, disabled: boolean) => {
    switch (theme) {
      case 'primary':
        return disabled ? 'bg-blue-300 text-white cursor-not-allowed' : 'bg-mui-primary-main text-white hover:bg-blue-500';
      case 'secondary':
        return disabled ? 'bg-gray-300 text-white cursor-not-allowed' : 'bg-gray-500 text-white hover:bg-gray-700';
      case 'success':
        return disabled ? 'bg-green-300 text-white cursor-not-allowed' : 'bg-green-500 text-white hover:bg-green-700';
      case 'danger':
        return disabled ? 'bg-red-300 text-white cursor-not-allowed' : 'bg-red-500 text-white hover:bg-red-700';
      case 'primaryOutline':
        return disabled ? 'border border-blue-300 text-blue-300 bg-white cursor-not-allowed' : 'border border-[#2196F3CC] text-mui-primary-main bg-white hover:bg-blue-50';
      default:
        return disabled ? 'bg-blue-300 text-white cursor-not-allowed' : 'bg-mui-primary-main text-white hover:bg-blue-500';
    }
  };

  const getSizeStyles = (size?: string) => {
    switch (size) {
      case 'small':
        return 'text-sm';
      case 'medium':
        return 'text-base';
      case 'large':
        return 'text-xl';
      default:
        return 'text-base';
    }
  };

  const loadingStyles = 'cursor-wait';

  return (
    <BaseButton
      {...props}
      slotProps={{
        root: (state: ButtonOwnerState) => ({
          className: classNames(
            'w-full h-14 rounded-full font-normal transition-all ease-linear duration-200',
            getColorStyles(theme, props.disabled || loading),
            getSizeStyles(size),
            loading ? loadingStyles : '',
            state.focusVisible ? 'outline-0 ring-2 ring-cyan-500' : ''
          ),
        }),
      }}
      ref={ref}
      disabled={props.disabled || loading}
    >
      {loading ? 'Loading...' : label}
    </BaseButton>
  );
});

export default Button;
