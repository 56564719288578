
import Image from "next/image";
import { IMediaInput } from "@types";
import { useImageVariants } from "./imageUtils";
import React from "react";

interface ImagePrepareProps {
  media: IMediaInput;
  alt: string;
  width?: number;
}

const ImagePrepare: React.FC<ImagePrepareProps> = ({ media, alt, width }) => {
  const { webpImage, image } = useImageVariants(media, width);

  let imageComponent;

  if (webpImage) {
    imageComponent = <Image src={webpImage.url} layout="fill" alt={alt} objectFit="cover" className="w-full h-full" loading="lazy" />;
  } else if (image) {
    imageComponent = <Image src={image.url} layout="fill" alt={alt} objectFit="cover" className="w-full h-full" loading="lazy" />;
  } else {
    imageComponent = <Image src={media.url} layout="fill" alt={alt} objectFit="cover" className="w-full h-full" loading="lazy" />;
  }

  return (
    <div className="w-full h-full relative">
      {imageComponent}
    </div>
  );
}

export default ImagePrepare;
