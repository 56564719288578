import { IAreaInput } from "@types";
import React from "react";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import cn from "classnames";

interface ParentAreaProps {
  areas: IAreaInput[]
  action: (area: IAreaInput | undefined) => void
  area?: IAreaInput
}

const ParentArea: React.FC<ParentAreaProps> = ({ areas, action, area: defaultArea }) => {
  const [selectedArea, setSelectedArea] = useState<undefined | IAreaInput>(defaultArea)
  const { locale } = useRouter()

  useEffect(() => {
    action(selectedArea)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedArea])

  useEffect(() => {
    setSelectedArea(defaultArea)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultArea])
  return (
    <div className="w-full">
      {areas.map((area) => (
        <div
          key={area.id}
          className={cn("w-full flex items-center justify-between cursor-pointer px-2 h-14 border-t gap-3", locale?.includes("ar-") ? "flex-row-reverse" : "flex-row")}
          // className="w-full flex items-center justify-between cursor-pointer px-2 h-14 border-b gap-3"
          onClick={() => setSelectedArea(area)}
        >
          <div className=" flex-1 overflow-hidden">
            <p className={cn("text-sm font-normal text-[#333] whitespace-nowrap overflow-hidden text-ellipsis w-full", locale?.includes("ar-") ? "text-right" : "text-left")}>
              {/* <p className="text-sm font-normal text-[#333] whitespace-nowrap overflow-hidden text-ellipsis text-left w-full"> */}
              {area.name}
            </p>
          </div>
          {selectedArea?.id === area.id ? <div className="flex justify-end items-center h-6 w-6">
            <div className={`w-6 h-6 border-2 border-[#008AF6] rounded-full flex justify-center items-center`}>
              <div className={`w-4 h-4 rounded-full bg-[#008AF6]`}></div>
            </div>
          </div> : null}
        </div>
      ))}
    </div>
  );
}

export default React.memo(ParentArea);