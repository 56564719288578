import type { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {}

const SearchIcon: React.FC<Props> = (props) => {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="11.058"
        cy="11.0586"
        r="7.06194"
        stroke={props.stroke ? props.stroke : `#999999`}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.23047 8.46619C8.98093 7.71573 9.99877 7.29413 11.0601 7.29413C12.1214 7.29413 13.1392 7.71573 13.8897 8.46619"
        stroke={props.stroke ? props.stroke : `#999999`}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.0063 20.0033L16.0547 16.0517"
        stroke={props.stroke ? props.stroke : `#999999`}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SearchIcon;
