import type {
  IAddressFieldsInput,
  IAreaListResponseInput,
  IAreaLocateInput,
  IBoundaryInput,
  ICountryListInput,
  IDefaultTermsResultInput,
  IGeoLocateInput,
  IGeoLocateOutput,
  IMapAutocompleteInput,
  IMapAutocompleteOutput,
  IMenuInput,
  IOfferListInput,
  ISitemapInput,
  ISyncInput,
  ITermDetailInput,
} from "@types";

import gate from "../gate";
import client from "../http-clients/api";

const commonApi = {
  getCountryList: () =>
    gate.get<ICountryListInput, never, never>({
      client,
      url: "country",
    }),
  getAreaList: (countryId: number) =>
    gate.get<IAreaListResponseInput, never, never>({
      client,
      url: `area/${countryId}`,
    }),
  getBoundary: (areaId: number | string) =>
    gate.get<IBoundaryInput, never, never>({
      client,
      url: `area/${areaId}/boundary`,
    }),
  geoLocate: (data: IGeoLocateOutput) =>
    gate.post<IGeoLocateInput, IGeoLocateOutput, never>({
      client,
      url: "area/locate",
      data,
    }),
  getTerms: () =>
    gate.get<IDefaultTermsResultInput, never, never>({
      client,
      url: "search/term",
    }),
  getTermDetail: (data: { term: string }) =>
    gate.post<ITermDetailInput, { term: string }, never>({
      client,
      url: "search/details",
      data,
    }),
  setTerm: (data: { term: string }) =>
    gate.post<ITermDetailInput, { term: string }, never>({
      client,
      url: "search/set-term",
      data,
    }),
  getTermSuggestion: (data: { term: string }) =>
    gate.post<IDefaultTermsResultInput, { term: string }, never>({
      client,
      url: "search/suggestion",
      data,
    }),
  getMenu: () =>
    gate.get<IMenuInput, never, never>({
      client,
      url: "misc/side-menu",
    }),

  getAddressFields: () =>
    gate.get<IAddressFieldsInput, never, never>({
      client,
      url: "user/dynamicaddress/fields",
    }),

  getSiteMap: () =>
    gate.get<ISitemapInput, never, never>({
      client,
      url: "product/list/site-map",
    }),

  getSync: () =>
    gate.get<ISyncInput, never, never>({
      client,
      url: "sync",
    }),

  getOfferList: (curser?: string) => {
    const url = curser ? `notify/offer/list/${curser}` : "notify/offer/list";
    return gate.get<IOfferListInput, any, never>({
      client,
      url: url,
    });
  },
  seenOffers: (data: { notification_ids: number[] }) =>
    gate.post<any, { notification_ids: number[] }, never>({
      client,
      url: `notify/offer/seen`,
      data,
    }),
  mapAutocomplete: (data: IMapAutocompleteOutput) =>
    gate.post<IMapAutocompleteInput, IMapAutocompleteOutput, never>({
      client,
      url: `general/maps/place/autocomplete`,
      data,
    }),
  placeDetail: (data: { place_id: string }) =>
    gate.post<
      {
        location: {
          lat: number;
          lng: number;
        };
      },
      { place_id: string },
      never
    >({
      client,
      url: `general/maps/place/detail`,
      data,
    }),

  getCurrentLocation: (data: { lat: number; lng: number }) =>
    gate.post<IAreaLocateInput, { lat: number; lng: number }, never>({
      client,
      url: `area/locate`,
      data,
    }),
};

export default commonApi;
