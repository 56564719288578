import type { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> { }

const EmptyCart: React.FC<Props> = (props) => {
  return (
    <svg {...props} width="161" height="161" viewBox="0 0 161 161" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Asset 1 1" clipPath="url(#clip0_40833_15961)">
        <g id="Layer 1">
          <path id="Vector" d="M160.51 80.25C160.51 100.42 153.07 118.85 140.78 132.94C140.77 132.96 140.75 132.97 140.74 132.99C133.68 141.09 125.02 147.75 115.25 152.49C104.68 157.62 92.8 160.5 80.26 160.5C56.11 160.5 34.45 149.84 19.74 132.95C7.44 118.86 0 100.42 0 80.25C0 35.93 35.93 0 80.26 0C124.59 0 160.51 35.93 160.51 80.25Z" fill="#D7EFFF" />
          <g id="Group">
            <path id="Vector_2" d="M55.131 37.1505C54.901 33.6405 55.321 28.4305 57.161 24.2205C58.951 20.1305 61.871 17.3205 66.801 17.3205H95.671C100.601 17.3205 103.521 20.1305 105.311 24.2205C107.151 28.4305 107.561 33.6405 107.341 37.1505C107.251 38.6205 108.361 39.8905 109.831 39.9805C111.301 40.0705 112.571 38.9605 112.671 37.4905C112.931 33.4505 112.501 27.3205 110.211 22.0805C107.871 16.7205 103.381 11.9805 95.681 11.9805H66.811C59.111 11.9805 54.621 16.7205 52.281 22.0805C49.991 27.3105 49.561 33.4405 49.821 37.4905C49.911 38.9605 51.181 40.0705 52.661 39.9805C54.131 39.8905 55.251 38.6205 55.151 37.1505H55.131Z" fill="#FFA000" />
            <path id="Vector_3" d="M132.74 43.9902V22.9902H28.7402V43.9902H132.74Z" fill="#42A5F5" />
            <path id="Vector_4" d="M29.3402 22.9902H132.27L123.8 36.4902L132.27 43.1702L140.74 49.9902H20.7402L37.8102 36.7702L29.3402 22.9902Z" fill="url(#paint0_linear_40833_15961)" />
            <path id="Vector_5" fillRule="evenodd" clipRule="evenodd" d="M140.78 132.941V48.6113H19.7402V132.951C34.4502 149.841 56.1102 160.501 80.2602 160.501C92.8002 160.501 104.68 157.621 115.25 152.491C125.02 147.751 133.68 141.091 140.74 132.991L140.78 132.941Z" fill="#42A5F5" />
            <path id="Vector_6" d="M51.7402 59.9902C54.5002 59.9902 56.7402 62.2302 56.7402 64.9902C56.7402 67.7502 54.5002 69.9902 51.7402 69.9902C48.9802 69.9902 46.7402 67.7502 46.7402 64.9902C46.7402 62.2302 48.9802 59.9902 51.7402 59.9902Z" fill="#0279D6" />
            <path id="Vector_7" d="M109.74 59.9902C112.5 59.9902 114.74 62.2302 114.74 64.9902C114.74 67.7502 112.5 69.9902 109.74 69.9902C106.98 69.9902 104.74 67.7502 104.74 64.9902C104.74 62.2302 106.98 59.9902 109.74 59.9902Z" fill="#0279D6" />
            <path id="Vector_8" d="M50.6699 90.301L53.5599 65.291L95.8499 108.331L77.1599 111.691L50.6699 90.311V90.301Z" fill="url(#paint1_linear_40833_15961)" />
            <path id="Vector_9" d="M140.741 91.9911V132.991C133.681 141.091 125.021 147.751 115.251 152.491L66.3507 110.281C66.3507 110.281 66.3407 110.281 66.3407 110.271L58.4707 103.481L69.9007 105.831H69.9207L84.7007 108.861L92.4407 104.841L108.771 96.3511L108.061 69.7011L107.871 62.5211L107.821 60.3711L107.791 59.3711L108.561 60.1311L110.501 62.0611L113.621 65.1411L114.611 66.1211L140.741 91.9911Z" fill="url(#paint2_linear_40833_15961)" fillOpacity="0.2" />
            <path id="Vector_10" d="M105.85 66.1205C105.85 63.8405 107.62 61.9805 109.79 61.9805C111.96 61.9805 113.73 63.8305 113.73 66.1205V80.4605C113.73 98.4305 99.8405 112.99 82.7005 112.99H78.7605C61.6205 112.99 47.7305 98.4305 47.7305 80.4605V66.1205C47.7305 63.8405 49.5005 61.9805 51.6705 61.9805C53.8405 61.9805 55.6105 63.8305 55.6105 66.1205V80.4605C55.6105 93.8605 65.9705 104.72 78.7505 104.72H82.6905C95.4705 104.72 105.83 93.8605 105.83 80.4605V66.1205H105.85Z" fill="#FFC107" />
          </g>
        </g>
      </g>
      <defs>
        <linearGradient id="paint0_linear_40833_15961" x1="80.7402" y1="22.9902" x2="80.7402" y2="49.9902" gradientUnits="userSpaceOnUse">
          <stop stopColor="#42A5F5" />
          <stop offset="0.5" stopColor="#3482C0" />
          <stop offset="1" stopColor="#27608F" />
        </linearGradient>
        <linearGradient id="paint1_linear_40833_15961" x1="82.3499" y1="87.691" x2="48.0999" y2="89.291" gradientUnits="userSpaceOnUse">
          <stop stopColor="#42A5F5" />
          <stop offset="1" stopColor="#0279D6" />
        </linearGradient>
        <linearGradient id="paint2_linear_40833_15961" x1="149.771" y1="97.1211" x2="80.3807" y2="113.101" gradientUnits="userSpaceOnUse">
          <stop stopColor="#42A5F5" />
          <stop offset="1" stopColor="#0279D6" />
        </linearGradient>
        <clipPath id="clip0_40833_15961">
          <rect width="160.51" height="160.5" fill="white" />
        </clipPath>
      </defs>
    </svg>

  );
};

export default EmptyCart;
