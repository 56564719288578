import { COOKIE_UID_KEY } from "./constants";
import { getCookie } from "./Cookies";
const Cookies = getCookie();

export abstract class AppGuid {
  private static s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .toUpperCase()
      .substring(1)
      .toUpperCase();
  }
  private static x4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .toLowerCase()
      .substring(1)
      .toLowerCase();
  }

  static generate() {
    const str = `${AppGuid.s4()}${AppGuid.s4()}-${AppGuid.s4()}-${AppGuid.s4()}-${AppGuid.s4()}-${AppGuid.s4()}${AppGuid.s4()}${AppGuid.s4()}`;
    Cookies.set(COOKIE_UID_KEY, str, {}, 356 * 2);
    return str;
  }
  static generateReqId() {
    const str = `${AppGuid.x4()}${AppGuid.x4()}${AppGuid.x4()}${AppGuid.x4()}`;
    return str;
  }
}
