import type { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> {}

const SupportIcon: React.FC<Props> = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.0037 12C21.0037 16.9726 16.9726 21.0037 12 21.0037"
        stroke={props.stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.99609 12C2.99609 7.02736 7.02721 2.99625 11.9998 2.99625"
        stroke={props.stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.99609 12C2.99623 13.0546 3.18381 14.1009 3.55008 15.0899C3.71759 15.5492 4.18986 15.8228 4.67165 15.7396L5.65825 15.5656C6.45566 15.425 6.99772 14.6771 6.88321 13.8756L6.58627 11.797C6.52878 11.3946 6.31051 11.0327 5.98139 10.7942C5.65227 10.5556 5.24046 10.4608 4.84015 10.5314L3.07883 10.8419"
        stroke={props.stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.0037 12C21.0037 7.02736 16.9726 2.99625 12 2.99625"
        stroke={props.stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.002 12C21.0018 13.0546 20.8143 14.1009 20.448 15.0899C20.2805 15.5492 19.8082 15.8228 19.3264 15.7396L18.3398 15.5656C17.5424 15.425 17.0004 14.6771 17.1149 13.8756L17.4118 11.797C17.4693 11.3946 17.6876 11.0327 18.0167 10.7942C18.3458 10.5556 18.7576 10.4608 19.1579 10.5314L20.9192 10.8419"
        stroke={props.stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4924 12.4912C13.4894 12.4912 13.4866 12.493 13.4855 12.4958C13.4843 12.4986 13.485 12.5018 13.4871 12.504C13.4892 12.5061 13.4925 12.5068 13.4952 12.5056C13.498 12.5044 13.4999 12.5017 13.4999 12.4987C13.5 12.4967 13.4992 12.4947 13.4978 12.4933C13.4964 12.4918 13.4944 12.4911 13.4924 12.4912"
        stroke={props.stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5061 12.4942C10.503 12.4942 10.5003 12.496 10.4992 12.4988C10.498 12.5016 10.4986 12.5048 10.5008 12.507C10.5029 12.5091 10.5061 12.5097 10.5089 12.5086C10.5117 12.5074 10.5135 12.5047 10.5135 12.5017C10.5137 12.4997 10.5129 12.4977 10.5115 12.4962C10.5101 12.4948 10.5081 12.4941 10.5061 12.4942"
        stroke={props.stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SupportIcon;
