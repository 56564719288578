import { useRouter } from "next/router";
import { useAppContext } from "@context/app";
import { useTranslations } from "next-intl";
import { useCallback } from "react";
import _ from "lodash";
import { segmentEvent } from "@utils/segment";
import { cartViewPaths } from "@utils/constants";
import useGTM from "@hooks/useGTM";
import { CartItemModel } from "@types";
import { updateCart } from "@utils/helpers";

const useCartPreview = () => {
  const t = useTranslations("");
  const { push, locale, reload, pathname } = useRouter();
  const { pushToDataLayer } = useGTM();

  const {
    setUserCart,
    cart,
    currency,
    decimal,
    country,
    currency_iso,
    user,
    eventProperties,
    area,
    freeShipping,
  } = useAppContext();

  const ItemsQty = cart.reduce(
    (previousValue, currentValue) => previousValue + currentValue.qty,
    0
  );

  const totalPrice = cart.reduce(
    (previousValue, currentValue) => previousValue + currentValue.qty * currentValue.price,
    0
  );

  const subtotalPrice = cart.reduce(
    (previousValue, currentValue) =>
      previousValue + currentValue.qty * (currentValue.oldPrice || currentValue.price),
    0
  );

  const totalDiscount = cart.reduce(
    (previousValue, currentValue) => previousValue + currentValue.qty * currentValue.discount,
    0
  );

  const totalRawPrice = cart.reduce(
    (previousValue, currentValue) =>
      previousValue + currentValue.qty * (currentValue.raw_price || currentValue.price),
    0
  );

  let giftsPrice = 0;
  _.forEach(cart, (value) => {
    _.forEach(value.gifts, (v) => {
      giftsPrice += v.wrapping?.price.price || 0;
    });
  });

  const goToCartView = useCallback(
    (close: () => void) => {
      if (cartViewPaths.includes(pathname)) {
        const commonData = {
          user_id: user.data?.id || -1,
          country_id: country?.id,
          country_slug: country?.slug,
          language: locale?.includes("en") ? "English" : "Arabic",
          total_items: ItemsQty,
          total_skus: cart.length,
          subtotal_amount: totalRawPrice,
          free_shipping_threshold: freeShipping ?? undefined,
          ...eventProperties,
        };

        segmentEvent("Cart Viewed", commonData);
        pushToDataLayer("Cart Viewed", commonData);
      }
      push("/cart").finally(() => {
        reload();
      });
      close();
    },
    [
      cart,
      country,
      eventProperties,
      freeShipping,
      ItemsQty,
      locale,
      pathname,
      push,
      pushToDataLayer,
      reload,
      totalRawPrice,
      user.data?.id,
    ]
  );

  const removeItemFromCart = useCallback(
    (item: CartItemModel) => {
      const updatedCart = cart.filter((sku) => sku.id !== item.id);
      const commonData = {
        action: "Delete",
        product_slug: item.productSlug,
        product_id: item.productId,
        brand_id: item.brand_id,
        brand_slug: item.brand_slug,
        category_id: item.category_id,
        category_slug: item.category_slug,
        subcategory_id: item.subcategory_id,
        subcategory_slug: item.subcategory_slug,
        sku_id: item.id,
        sku_slug: item.skuSlug,
        currency: currency_iso.toUpperCase(),
        country_id: country?.id,
        country_slug: country?.slug,
        area_id: area?.id,
        area_slug: area?.slug,
        user_id: user.data?.id || -1,
        language: locale?.includes("en") ? "English" : "Arabic",
        sku_old_price: item.oldPrice ?? item.price,
        sku_new_price: item.price,
        from_page: "cart",
        from_entity: "",
        from_entity_slug: "",
        from_page_section: "",
        from_page_section_entity: "",
        from_page_section_slug: "",
        cart_count: ItemsQty ? ItemsQty - item.qty : 0,
        quantity: ItemsQty ? ItemsQty - item.qty : 0,
      };

      segmentEvent("SKU Removed", commonData);
      pushToDataLayer("SKU Removed", commonData);

      setUserCart([...updatedCart]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      cart,
      currency_iso,
      country,
      area,
      user.data?.id,
      locale,
      ItemsQty,
      pushToDataLayer,
      segmentEvent,
      setUserCart,
    ]
  );

  const changeQty = useCallback(
    (id: string | number, count: number) => {
      const data: CartItemModel[] = updateCart();
      for (const item of data) {
        if (item.id === id) {
          item.qty = count;
          item.gifts = [...item.gifts.slice(0, count)];
        }
      }
      setUserCart([...data]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cart, setUserCart]
  );

  return {
    t,
    cart,
    currency,
    decimal,
    locale,
    ItemsQty,
    totalPrice,
    giftsPrice,
    goToCartView,
    removeItemFromCart,
    subtotalPrice,
    totalDiscount,
    changeQty,
  };
};

export default useCartPreview;
