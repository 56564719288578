import cn from 'classnames';
import useProgressBar from './useProgressBar';
import FreeShipping from '@svg/redesign/FreeShipping';
import CheckCircle from '@svg/redesign/CheckCircle';
import classNames from 'classnames';

interface ProgressBarProps {
  value: number;
  maxValue: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ value, maxValue }) => {
  const percentage = Math.min((value / maxValue) * 100, 100);
  const { currency, isRTL } = useProgressBar();

  return (
    <div dir={isRTL ? 'rtl' : 'ltr'} className="w-full flex gap-2 items-center justify-center max-w-[250px] mx-auto">
      <div className="flex-1 relative ">
        <div className="w-full h-1 bg-mui-primary-light bg-opacity-60 overflow-hidden">
          <div
            className="h-full bg-mui-primary-main  transition-all ease-in-out duration-200"
            style={{ width: `${percentage}%` }}
          ></div>
        </div>
        {percentage >= 100 ? (
          <div
            className={cn(
              'absolute z-1 -top-2.5',
              isRTL ? 'left-0' : 'right-0'
            )}
          >
            <CheckCircle />
          </div>
        ) : (
          <div style={isRTL ? { left: percentage > 70 ? `calc(${100 - percentage}%)` : `calc(${100 - percentage}% - 40px)` } : { right: percentage > 70 ? `calc(${100 - percentage}%)` : `calc(${100 - percentage}% - 40px)` }} className={classNames(' absolute transition-all ease-in-out duration-200 -top-[18px]', isRTL ? "scale-x-[-1]" : "")}>
            <FreeShipping />
          </div>
        )
        }
      </div>
      <div dir="ltr" className={cn(percentage >= 100 ? "text-mui-primary-main" : "text-mui-primary-light", "text-sm flex space-x-0.5")}>
        <bdi>{currency}</bdi>
        <span>{maxValue}</span>
      </div>
    </div>
  );
};

export default ProgressBar;
