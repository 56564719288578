import { useEffect, useMemo } from "react";
import { useAppContext } from "@context/app";
import { useTranslations } from "next-intl";
import { useRouter } from "next/router";

const useFreeDelivery = () => {
  const { currency, cart, freeShipping, setUserFreeShipping, decimal } = useAppContext();
  const t = useTranslations("");
  const { locale } = useRouter();

  useEffect(() => {
    for (const item of cart) {
      if (!item.raw_price) {
        setUserFreeShipping(null);
        break;
      }
    }
  }, [cart, setUserFreeShipping]);

  const totalPrice = useMemo(() => {
    return cart.reduce((total, item) => total + item.qty * (item.raw_price || 0), 0);
  }, [cart]);

  const maxValue = freeShipping || 0;
  const remaining = useMemo(
    () => (maxValue - totalPrice).toFixed(decimal),
    [maxValue, totalPrice, decimal]
  );
  const isFree = totalPrice >= maxValue;

  return {
    currency,
    freeShipping,
    totalPrice,
    remaining,
    isFree,
    cart,
    t,
    locale,
  };
};

export default useFreeDelivery;
