import { useCallback } from "react";

const useGTM = () => {
  const pushToDataLayer = useCallback((event: string, eventData: Record<string, any>) => {
    if (window && window.dataLayer) {
      window.dataLayer.push({
        event,
        ...eventData,
      });
    }
  }, []);

  return {
    pushToDataLayer,
  };
};

export default useGTM;
