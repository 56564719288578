import { useRouter } from "next/router";
import { useRef, useState } from "react";
import { BsChevronUp } from "react-icons/bs";

interface AccordionProps {
  title: string;
  open?: boolean;
  activeFlag?: boolean;
}

const Accordion: React.FC<AccordionProps> = ({
  title,
  open,
  children,
  activeFlag,
}) => {
  const [isOpened, setOpened] = useState<boolean>(false);
  const [height, setHeight] = useState<string>("0px");
  const contentElement = useRef<any>(null);
  const { locale } = useRouter();

  const HandleOpening = () => {
    setOpened(!isOpened);
    setHeight(!isOpened ? `${contentElement?.current?.scrollHeight}px` : "0px");
  };

  function renderTitle() {
    let titleClasses = "transition-all duration-200 ease-in-out flex justify-between items-center cursor-pointer h-12";
    if (activeFlag) {
      if (isOpened) {
        titleClasses += " text-brandBlue";
      } else {
        titleClasses += " text-[#999]";
      }
    } else {
      titleClasses += " text-[#333]";
    }
    const directionStyle = locale?.includes("ar") ? "rtl" : "ltr";

    return (
      <div onClick={HandleOpening} className={titleClasses} dir={directionStyle}>
        <p className="font-normal text-base capitalize w-max">{title}</p>
        <BsChevronUp className={`transition-all duration-200 ease-in-out ${isOpened ? "" : "rotate-180"}`} />
      </div>
    );
  }

  function renderContent() {
    const contentStyle = { height: height };

    return (
      <div ref={contentElement} style={contentStyle} className="w-full overflow-hidden transition-all duration-200">
        {children}
      </div>
    );
  }


  return (
    <div className="w-full">
      {renderTitle()}
      {renderContent()}
    </div>
  );
};

export default Accordion;
