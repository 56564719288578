import type { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> { }

const OfferIcon: React.FC<Props> = (props) => {
  return (
    <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group">
        <path id="Path" d="M7.77026 12.2274L12.2277 7.77002" stroke={props.stroke || "#333333"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path id="Path_2" d="M12.4296 12.0761C12.5251 12.1747 12.5232 12.3319 12.4254 12.4282C12.3275 12.5245 12.1703 12.5239 12.0732 12.4268C11.9761 12.3297 11.9755 12.1725 12.0718 12.0746C12.1681 11.9768 12.3253 11.9749 12.4239 12.0704L12.4296 12.0761" stroke={props.stroke || "#333333"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path id="Path_3" d="M7.57042 7.92392C7.4749 7.8253 7.47679 7.66811 7.57464 7.57181C7.6725 7.47552 7.8297 7.47614 7.92678 7.57322C8.02386 7.6703 8.02448 7.8275 7.92819 7.92536C7.83189 8.02321 7.6747 8.0251 7.57608 7.92958L7.57042 7.92392" stroke={props.stroke || "#333333"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <circle id="Oval" cx="10" cy="10" r="9" stroke={props.stroke || "#333333"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </svg>

  );
};

export default OfferIcon;
