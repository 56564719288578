import ChevronLeftFilled from "@svg/redesign/ChevronLeftFilled";
import { useTranslations } from "next-intl";
import FreeDelivery from "../FreeDelivery/FreeDelivery";
import classNames from "classnames";

interface HeaderProps {
  close: () => void;
  cartLength: number;
  locale: string | undefined;
}

const Header: React.FC<HeaderProps> = ({ close, cartLength, locale }) => {
  const t = useTranslations("");

  return (
    < div className="w-full h-max border-b border-mui-divider flex flex-col gap-3 pb-3" >
      <FreeDelivery />
      <div className="w-full flex items-center justify-between">
        <ChevronLeftFilled onClick={close} className={classNames(locale?.includes("ar-") ? "rotate-180" : "rotate-0", " cursor-pointer")} />
        <p className="font-normal text-2xl text-mui-text-primary">{t("client_cart_cart")}</p>
        {cartLength ? <p className="font-medium text-sm text-mui-text-disabled">{cartLength > 1 ? `${cartLength} ${t("client_giftoptions_items")}` : `${cartLength} ${t("client_giftoptions_item")}`}</p> : <p></p>}
      </div>
    </div >
  )
};

export default Header;