import { useAppContext } from '@context/app';
import { convertNumber } from '@utils/digits';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import React from 'react';

interface PriceDisplayProps {
  price: number;
  className?: string;
  isNegative?: boolean;
}


const PriceDisplay: React.FC<PriceDisplayProps> = ({ price, className, isNegative }) => {
  const { currency, decimal } = useAppContext()
  const { locale } = useRouter()
  return (
    <p dir='ltr' className={classNames("font-semibold text-xl text-black flex items-center justify-end  w-max ", className)} >
      <bdi className='text-sm pb-1 p-0.5'>
        {currency}
      </bdi>
      {isNegative ? <bdi >-</bdi> : null}
      <span>{convertNumber(locale, price.toFixed(decimal))}</span>
    </p>
  );
};

export default PriceDisplay;
