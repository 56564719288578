import type { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> { }

const FreeShipping: React.FC<Props> = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="40" height="28" viewBox="0 0 40 28" fill="none">
      <path d="M0 2.63158C0 1.1782 1.1782 0 2.63158 0H23.4211C24.7291 0 25.7895 1.06038 25.7895 2.36842V22.6316H0V2.63158Z" fill="#42A5F5" />
      <path d="M18.6895 9.01164V0H7.69531V9.19187L9.13718 7.75001L10.3988 9.01164L11.6604 7.75001L13.1023 9.19187L14.5442 7.75001L15.986 9.19187L17.4279 7.75001L18.6895 9.01164Z" fill="#FFC107" />
      <path d="M25.7891 7.89258V22.761H27.4996C27.8943 21.7961 29.3943 19.8663 32.2364 19.8663C35.0785 19.8663 36.8417 21.7961 37.368 22.761H39.3417V16.1821C39.3417 14.0768 37.8943 11.7084 37.368 10.261C36.8417 8.81363 34.9557 8.02416 33.947 7.89258H25.7891Z" fill="#2C6EA3" />
      <path fillRule="evenodd" clipRule="evenodd" d="M13.6845 22.1035H3.1582C3.15924 19.7788 5.51524 17.8945 8.42136 17.8945C11.3275 17.8945 13.6835 19.7788 13.6845 22.1035Z" fill="#334A5E" />
      <path fillRule="evenodd" clipRule="evenodd" d="M37.3623 22.1035H26.8359C26.837 19.7788 29.193 17.8945 32.0991 17.8945C35.0052 17.8945 37.3612 19.7788 37.3623 22.1035Z" fill="#334A5E" />
      <rect y="22.1055" width="40" height="2.89474" fill="#334A5E" />
      <circle cx="32.1036" cy="23.422" r="3.94737" fill="#40596B" />
      <circle cx="32.1063" cy="23.4247" r="1.57895" fill="#D9D9D9" />
      <circle cx="8.42198" cy="23.422" r="3.94737" fill="#40596B" />
      <circle cx="8.42465" cy="23.4247" r="1.57895" fill="#D9D9D9" />
      <path d="M27.6289 9.47266V15.6569H37.2342L27.6289 9.47266Z" fill="#CDD6E0" />
      <path d="M27.6289 9.47266H32.8921C34.8657 9.60424 35.6026 9.84108 36.4447 12.8937C37.0731 15.1717 37.2033 15.5886 37.2289 15.6534L37.2342 15.6569C37.2342 15.6569 37.2376 15.6755 37.2289 15.6534L27.6289 9.47266Z" fill="#F2F2F2" />
    </svg>
  );
};

export default FreeShipping;
