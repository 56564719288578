import type { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> { }

const CheckCircle: React.FC<Props> = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_41009_14022)">
        <rect width="24" height="24" rx="12" fill="#008AF6" />
        <circle cx="12.0018" cy="11.9998" r="9.00375" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8.44336 12.3387L10.6113 14.5066L10.5973 14.4926L15.4883 9.60156" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <rect x="2" y="2" width="20" height="20" rx="10" stroke="#008AF6" strokeWidth="4" />
      <defs>
        <clipPath id="clip0_41009_14022">
          <rect width="24" height="24" rx="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CheckCircle;
