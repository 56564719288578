import SwiperCore, { Mousewheel, FreeMode, Scrollbar } from "swiper";
import { Swiper } from "swiper/react";
interface SliderProps {
  gap?: number;
  isCenter?: boolean;
}

const Carousel: React.FC<SliderProps> = ({ children, gap, isCenter }) => {
  SwiperCore.use([FreeMode, Scrollbar, Mousewheel]);
  return (
    <div className="w-full overflow-hidden">
      <Swiper
        slidesPerView={"auto"}
        allowTouchMove={true}
        freeMode={true}
        mousewheel={true}
        modules={[Mousewheel, FreeMode]}
        edgeSwipeThreshold={0}
        spaceBetween={gap || 0}
        className={isCenter ? "!w-max max-w-full" : ""}
        watchSlidesProgress={true}
      >
        {children}
      </Swiper>
    </div>
  );
};

export default Carousel;
