import type { AppContext, AppInitialProps, AppProps, NextWebVitalsMetric } from "next/app";
import type { PageProps, PageWithLayout } from "@types";
import type { IntlError } from "use-intl";
import dynamic from "next/dynamic";
const ADJUST = dynamic(() => import("@elements/adjust"), {
  ssr: false,
});
import React, { useEffect, useMemo } from "react";
import { IntlProvider, IntlErrorCode } from "use-intl";
import { SWRConfig } from "swr";
import { getLayout as getDefaultLayout, noLayout } from "@layouts/default-layout";
import ResizeHandler from "@elements/resize-handler";
import LocaleHandler from "@elements/locale-handler";
import { fetcher, serialize } from "@utils/swr";
import { event } from "@utils/gtag";
import AppProvider from "context/app";
import { ManagedUIContext } from "@context/ui-managed-context";
import "keen-slider/keen-slider.min.css";
import "rc-slider/assets/index.css";
import "react-toastify/dist/ReactToastify.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import "swiper/css/scrollbar";
import "../styles/index.css";
import { useOneSignal } from "@utils/oneSignal";
import { readyAnonymousId } from "@utils/segment";
import getConfig from "next/config";
import App from "next/app";
import { setAppContext } from "@utils/Cookies";
import { commonApi } from "gate";
const { publicRuntimeConfig } = getConfig();
import { parse } from "next-useragent";
const Segment = dynamic(() => import("@elements/segment"), {
  ssr: false,
});
const ONE_SIGNAL = dynamic(() => import("@elements/oneSignal"), { ssr: false });
const GTM = dynamic(() => import("@elements/gtm"), {
  ssr: false,
});
const GoogleTagManager = dynamic(() => import("@elements/googleTagManager.tsx"), { ssr: false });
const Zendesk = dynamic(() => import("@elements/zendesk"), {
  ssr: false,
});
// const MoengageInit = dynamic(() => import("@elements/redesign/moenGage"), {
//   ssr: false,
// });

// Conditionally inject axe into the page.
// This only happens outside of production and in a browser (not SSR).
// if (typeof window !== "undefined" && process.env.NODE_ENV !== "production") {
//   const ReactDOM = require("react-dom");
//   const axe = require("@axe-core/react");
//   axe(React, ReactDOM, 1000);
// }

function onError(error: IntlError) {
  if (error.code === IntlErrorCode.MISSING_MESSAGE) {
    // Missing translations are expected and should only log an error
    console.error(error);
  } else {
    // Other errors indicate a bug in the app and should be reported
    // reportToErrorTracking(error);
    console.log({ error });
  }
}

function getMessageFallback({
  namespace,
  key,
  error,
}: {
  namespace?: string;
  key: string;
  error: IntlError;
}) {
  const path = [namespace, key].filter((part) => part != null).join(".");

  if (error.code === IntlErrorCode.MISSING_MESSAGE) {
    return `${path} is not yet translated`;
  } else {
    return `Dear developer, please fix this message: ${path}`;
  }
}

export function reportWebVitals(metric: NextWebVitalsMetric) {
  const { id, name, label, value } = metric;
  event({
    action: name,
    category: label === "web-vital" ? "Web Vitals" : "Next.js custom metric",
    label: id, // id unique to current page load
    value: Math.round(name === "CLS" ? value * 1000 : value), // values must be integers
    non_interaction: true,
  });
}

function MyApp(props: AppProps<PageProps> & { err: any }) {
  const {
    Component,
    router,
    err,
    // @ts-ignore
    pageProps: { user, messages, now, ...restProps },
  } = props;

  const fallback = props.pageProps.fallback || {};
  useEffect(() => {
    document.body.classList?.remove("loading");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useMemo(() => {
    router.prefetch = async () => {};
  }, [router]);
  const { getLayout = getDefaultLayout, translationFile } = Component as PageWithLayout<PageProps>;
  const locale = router.locale || router.defaultLocale || "ar";
  useOneSignal();
  readyAnonymousId();

  return (
    <IntlProvider
      // To achieve consistent date, time and number formatting
      // across the app, you can define a set of global formats.
      formats={{
        dateTime: {
          short: {
            day: "numeric",
            month: "short",
            year: "numeric",
          },
        },
      }}
      locale={locale}
      messages={{
        ...require(`../locales/${locale.split("-")[0]}/translation.json`),
        ...(translationFile
          ? require(`../locales/${locale.split("-")[0]}/${translationFile}.json`)
          : {}),
        ...messages,
      }}
      // Providing an explicit value for `now` ensures consistent formatting of
      // relative values regardless of the server or client environment.
      // @ts-ignore
      now={new Date(now)}
      // Also an explicit time zone is helpful to ensure dates render the
      // same way on the client as on the server, which might be located
      // in a different time zone.
      getMessageFallback={getMessageFallback}
      onError={onError}
    >
      <AppProvider>
        <SWRConfig value={{ fetcher, use: [serialize], fallback }}>
          <ManagedUIContext>
            <ResizeHandler />
            <LocaleHandler router={router} />
            {publicRuntimeConfig.GOOGLE_TAG_MANAGER_ID && <GTM router={router} />}
            <GoogleTagManager />
            <Zendesk />
            {(getLayout || noLayout)(Component, {
              ...restProps,
              err,
            })}
            {publicRuntimeConfig.ANALYTICS_WRITE_KEY && <Segment />}
            {typeof window !== "undefined" && !window.location.origin.includes("dabdoob.com") && (
              <ADJUST />
            )}
            {publicRuntimeConfig.ONESIGNAL_TOKEN && <ONE_SIGNAL />}
            {/* <MoengageInit />  */}
          </ManagedUIContext>
        </SWRConfig>
      </AppProvider>
    </IntlProvider>
  );
}

MyApp.getInitialProps = async (appContext: AppContext): Promise<AppInitialProps> => {
  const appProps = await App.getInitialProps(appContext);
  const { req, query } = appContext.ctx;
  setAppContext(appContext);
  const ssr = typeof query.ssr === "string" ? query.ssr : "";
  const ua = parse(req?.headers["user-agent"] || "");

  let err: any = null;
  try {
    const fallback = ssr === "1" || ua.isBot ? await commonApi.getSync().fetch() : undefined;
    return {
      ...appProps,
      pageProps: {
        ...appProps.pageProps,
        appFallback: fallback?.data,
        err,
      },
    };
  } catch (error) {
    err = error;
  }

  return {
    ...appProps,
    pageProps: {
      ...appProps.pageProps,
      err,
    },
  };
};

export default MyApp;
